import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { AuthResponse } from 'msal';
import { KeyVaultService } from '../../services/key-vault.service';

@Component({
  selector: 'app-opc-simulator',
  templateUrl: './opc-simulator.component.html',
  styleUrls: ['./opc-simulator.component.css'],
})
export class OpcSimulatorComponent implements OnInit {
  to: Date;
  from: Date;

  showResult = false;
  apiResult: any;

  adtToken: string;

  //Sandbox ADT URL
  adtUrl =
    'https://' +
    localStorage.getItem('azureDigitalTwinUrl') +
    '/query?api-version=2020-10-31';

  //azureDigitalTwinScope = ["8f513527-1e6b-4d54-8456-22abbb0ea1d9"];
  azureDigitalTwinScope = ['https://digitaltwins.azure.net/Read.Write'];

  constructor(private http: HttpClient, private auth: MsalService, private keyVaultSvc: KeyVaultService) {
    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    this.from = new Date(curr.setDate(first));
    this.to = new Date(curr.setDate(last));
  }
  innerWidth: any;
  innerHeight: any;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth - 200;
    this.innerHeight = window.innerHeight - 150;
  }
  ngOnInit(): void {
    this.onResize();
  }
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
  };

  mockGetAPi() {
    //this.showResult = false;
    this.apiResult =
      'MOCK GET API : Fetching result from backend ........................';
    let apiUrl: string = environment.coreApiBaseUrl + 'securedApi/testGetApi';
    let idToken = localStorage.getItem('msal.idtoken');

    let header_node = {
      Accept: 'application/json',
      rejectUnauthorized: 'false',
      Authorization: 'Bearer ' + idToken,
    };

    this.http.get(apiUrl, { headers: header_node }).subscribe((data) => {
      console.log(data);
      this.showResult = true;
      this.apiResult = data;
    });
  }

  mockPostAPi() {
    this.apiResult =
      'MOCK POST API : Fetching result from backend ........................';
    let apiUrl: string = environment.coreApiBaseUrl + 'securedApi/testPostApi';
    let idToken = localStorage.getItem('msal.idtoken');

    const body = {
      fromDate: '',
      toDate: '',
      adtUrl: this.adtUrl,
      accessToken: this.adtToken,
      adtQuery: "SELECT * FROM DIGITALTWINS T WHERE T.$dtId = 'PD_01'",
    };

    let header_node = {
      Accept: 'application/json',
      rejectUnauthorized: 'false',
      Authorization: 'Bearer ' + idToken,
    };

    this.http.post(apiUrl, body, { headers: header_node }).subscribe((data) => {
      console.log(data);
      this.showResult = true;
      this.apiResult = data;
    });
  }

  mockPostAdtAPi() {
    this.apiResult =
      'MOST POST ADT API : Fetching result from backend ........................';
    // this.fetchPostAdtAPi()
    //   .subscribe(data => {
    //     this.showResult = true;
    //     this.apiResult = data;
    //   });

    this.fetchTokenAndGetPdOperationData();
  }

  fetchPostAdtAPi(): Observable<any> {
    let apiUrl: string =
      environment.coreApiBaseUrl + 'securedApi/testPostAdtApi';

    let idToken = localStorage.getItem('msal.idtoken');
    let header_node = {
      Accept: 'application/json',
      rejectUnauthorized: 'false',
      Authorization: 'Bearer ' + idToken,
    };

    const body = {
      fromDate: '',
      toDate: '',
      adtUrl: this.adtUrl,
      accessToken: this.adtToken,
      adtQuery: "SELECT * FROM DIGITALTWINS T WHERE T.$dtId = 'PD_01'",
    };
    //console.log(body)
    return this.http.post(apiUrl, body, { headers: header_node });
  }

  fetchTokenAndGetPdOperationData() {
    this.auth
      .acquireTokenSilent({ scopes: this.azureDigitalTwinScope })
      .then((response: AuthResponse) => {
        this.adtToken = response.accessToken;
        localStorage.setItem('adtToken', this.adtToken);
        //console.log(this.adtToken);
      })
      .catch((err) => {
        console.log(err);
      })
      .then((_) => {
        this.fetchPostAdtAPi().subscribe((data) => {
          this.showResult = true;
          this.apiResult = data;
        });
      });
  }
}
