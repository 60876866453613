import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngx-number-card-asset-status',
  templateUrl: './ngx-number-card-asset-status.component.html',
  styleUrls: ['./ngx-number-card-asset-status.component.css']
})
export class NgxNumberCardAssetStatusComponent {

  @Input() inputStatusCardData : any[];
  //single: any[];
  view: any[] = [220,200];

  colorScheme = {
    domain: ['#085f01', 'darkred', '#085f01', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  cardColor: string = '#1b1a1a';

  constructor() {
    //Object.assign(this, { single });
  }

  onSelect(event) {
    console.log(event);
  }

}
