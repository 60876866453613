<!-- <div class="container" [ngStyle]="{'height.px':innerHeight+250,'width.px':innerWidth-90,'min-width.px':innerWidth+70}"> -->
    <div class="container" [ngStyle]="{'width.px':innerWidth-90,'min-width.px':innerWidth+70}">
        <div class="row">
            <ng-container *ngFor="let card of cardData">
                <div class="col-md-4 bg-grey" [ngClass]="card?.class">
                    <ng-container>
                        <div class="bg-grey">
                            <div>
                                <p [ngClass]="card?.label?.class">{{card?.label.name}}</p>
                            </div>
                            <div>
                                <!-- Widgets and charts condtions start here -->
                                <ng-container *ngIf="card?.type === 'vertical-bar-chart'">
                                    <app-horizontal-large-widget *ngIf="card?.loading" [widgetType]="card?.type"
                                        [widgetName]="card?.widgetName" [leftFooterName]="card?.leftFooterName"
                                        [rightFooterName]="card?.rightFooterName" [widgetData]="card?.data?.widgetData">
                                    </app-horizontal-large-widget>
                                    <ng-container [ngTemplateOutlet]="!card?.loading ? loader : ''"></ng-container>
                                </ng-container><!-- End vertical-bar-chart -->
                                <ng-container *ngIf="card?.type === 'pareto-chart'">
                                    <div class="ml-30">
                                        <!-- <app-ngx-pareto-chart 
                                            *ngIf="card?.loading" 
                                            [barChartData]="card?.data?.barChartData"
                                            [lineChartData]="card?.data?.lineChartData" 
                                            [screenName]="card?.screenName"
                                        >
                                    </app-ngx-pareto-chart> -->
                                        <app-horizontal-large-widget *ngIf="card?.loading"
                                            [widgetType]="'vertical-bar-chart'" [widgetName]="''" [leftFooterName]=""
                                            [rightFooterName]="" [isIssuesLoaded]="card?.loading"
                                            [xAxisLabelName]="xAxisLabelName" [yAxisLabelName]="yAxisLabelName"
                                            [widgetData]="card?.data?.barChartData">
                                        </app-horizontal-large-widget>
                                    </div>
                                    <ng-container [ngTemplateOutlet]="!card?.loading ? loader : ''"></ng-container>
                                </ng-container><!-- End pareto-chart -->
                                <ng-container *ngIf="card?.type === 'kendo-chart-series'">
                                    <div *ngIf="card?.loading" class="mt18">
                                        <kendo-chart [style.height.px]="height" [transitions]="false">
                                            <kendo-chart-series>
                                                <kendo-chart-series-item [autoFit]="autofit" [size]="30" type="donut"
                                                    [data]="card?.data?.series" categoryField="name" field="value">
                                                    <kendo-chart-series-item-labels position="outsideEnd" color="#000"
                                                        [content]="labelContent">
                                                    </kendo-chart-series-item-labels>
                                                </kendo-chart-series-item>
                                            </kendo-chart-series>
                                            <kendo-chart-legend [offsetX]="offsetX"
                                            [offsetY]="15" [visible]="true" [position]="card?.legend?.position"
                                                [orientation]="card?.legend?.orientation">
                                            </kendo-chart-legend>
                                        </kendo-chart>
                                    </div>
                                    <ng-container [ngTemplateOutlet]="!card?.loading ? loader : ''"></ng-container>
                                </ng-container><!-- End kendo-chart-series -->
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    
    <!-- Loader template -->
    <ng-template #loader>
        <div class="loader">
            <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
        </div>
    </ng-template>