<div class="col-md-6">
    <div class="csv-sim">
        
            <div class="title-info">
                CSV Simulator
            </div>
            <br>
     <div class="file-upload-card">
            
            <div style="width: 500px">

            <input type="file" id="customFile" (change)="selectFile($event)"><br>
            <button class="btn btn-info" (click)="upload()">Upload and Start Simulator</button>
            </div>
     </div>

</div>
</div>