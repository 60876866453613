import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-opc-simulator',
  templateUrl: './service-opc-simulator.component.html',
  styleUrls: ['./service-opc-simulator.component.css']
})
export class ServiceOpcSimulatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
