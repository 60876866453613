import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-compact-widget',
  templateUrl: './compact-widget.component.html',
  styleUrls: ['./compact-widget.component.css']
})
export class CompactWidgetComponent implements OnInit {
  @Input() widgetName : string;
  @Input() leftFooterName : string;
  @Input() rightFooterName : string;
  @Input() widgetType : string;
  @Input() widgetData : any;

  footerColor = "red";
  constructor() { }

  ngOnInit(): void {
  }

}
