import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-medium-widget',
  templateUrl: './horizontal-medium-widget.component.html',
  styleUrls: ['./horizontal-medium-widget.component.css']
})
export class HorizontalMediumWidgetComponent implements OnInit {

  @Input() widgetName : string;
  @Input() leftFooterName : string;
  @Input() rightFooterName : string;
  @Input() widgetType : string;
  @Input() widgetData : any;


  constructor() { }

  ngOnInit(): void {
  }

}
