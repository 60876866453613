import { Component, HostListener, Input, OnInit } from '@angular/core';
import { single } from './pie_grid_data.js';

@Component({
  selector: 'app-ngx-pie-grid',
  templateUrl: './ngx-pie-grid.component.html',
  styleUrls: ['./ngx-pie-grid.component.css']
})
export class NgxPieGridComponent implements OnInit {
  scrHeight:any;
  scrWidth:any;
  height;
  width;
  @Input() screen = 'PD';
  @Input() scale = { max: 200 };
  @Input() inputPieGridStock: any;
  @Input() inputPieGridConsumed: any;
  @Input() gridName: string;
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if(this.scrWidth > 641 && this.scrWidth <= 961){
      this.height = '85';
      this.width = '87';
    }
    else if( this.scrWidth > 961 && this.scrWidth <= 1025){
      this.height = '85';
      this.width = '87';
    }
    else{
      this.height = '90';
      this.width = '90';
    }
  }
  single: any[];
  view: any[] = [340, 160];
  showLegend: boolean = true;
  showLabels: boolean = true;
  label: string = 'Total'
  colorScheme = {
    domain: ['#085f01', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  public colors: any[] = [{
    to: 30,
    color: '#d13911'
  }, {
    from: 25,
    to: 100,
    color: '#0c7a28'
  }];

  constructor() {
    Object.assign(this, { single });

  }

  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    this.onOrientationChange();
    // // if (this.gridName == 'Availability') {
    // //   this.inputPieGridData = inputPieGridData2;
    // //   this.label = 'Total(hrs)'
    // // } else {
    // //   this.inputPieGridData = inputPieGridData1;
    // // }
    // if (this.gridName == 'Availability') {
    //   this.inputPieGridData = inputavailability1;
    //   this.inputPieGridData1 = inputavailability2;
    //   this.label = 'Total(hrs)'
    // } else {
    //   this.inputPieGridData = inputPieGridSparesInventory1;
    //   this.inputPieGridData1 = inputPieGridSparesInventory2;
    // }
  }

}
// export var inputPieGridSparesInventory1 = 73;
// export var inputPieGridSparesInventory2 = 27;
// export var inputavailability1 = 83;
// export var inputavailability2 = 17;
// export var inputPieGridData1 = [
//   {
//     "name": "In Stock",
//     "value": 80
//   },
//   {
//     "name": "Consumed",
//     "value": 30
//   }
// ];
// export var inputPieGridData2 = [
//   {
//     "name": "Uptime",
//     "value": 100
//   },
//   {
//     "name": "DownTime",
//     "value": 20
//   }
// ];
