







,<div class="container"><div class="header-logo-image"><img class="mt-2" src="assets/images/EY_Logo_Beam_RGB.png" width="80px" alt="Logo"></div>
<div class="browser-text"><h4>You have been logged out successfully. Please close the browser!!</h4></div>
</div>