<div class="container1 p-0">
  <p class="h4 text-center">CNC</p>
  <mat-tab-group mat-align-tabs="left" dynamicHeight="true" (selectedTabChange)="tabChanged($event)" [selectedIndex]="getSelectedIndex()">
    <mat-tab label="Asset Health" *ngIf='userRole == "Admin" || userRole == "VP of Operations" || userRole == "TV User"'>
      <ng-template matTabContent>
          <app-asset-health-cnc></app-asset-health-cnc>
      </ng-template>
    </mat-tab>
    <mat-tab label="Operations Summary" *ngIf='userRole == "Admin" || userRole == "VP of Operations" || userRole == "TV User"'>
      <ng-template matTabContent>
        <app-operations-cnc></app-operations-cnc>
      </ng-template>
    </mat-tab>
    <mat-tab label="Predictions" *ngIf='userRole == "Admin" || userRole == "VP of Operations" || userRole == "TV User"'>
      <ng-template matTabContent>
          <app-predictions-cnc></app-predictions-cnc>
      </ng-template>
    </mat-tab>
    <mat-tab label="Remote Operations Cockpit" *ngIf='userRole == "Admin" || userRole == "VP of Operations" || userRole == "TV User"'>
      <ng-template matTabContent>
          <app-remote-operations-cnc></app-remote-operations-cnc>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
