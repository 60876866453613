import { Component, Input, OnInit } from '@angular/core';
import { multi } from './line-chart-data.js';

@Component({
  selector: 'app-ngx-line-chart',
  templateUrl: './ngx-line-chart.component.html',
  styleUrls: ['./ngx-line-chart.component.css']
})
export class NgxLineChartComponent {

  @Input() lineChartInputData : any[];
  //multi: any[];
  view: any[] = [240,180];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;
  legendPosition: string = 'below';
  colorScheme = {
    domain: ['#085f01', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor() {
    //Object.assign(this, { multi });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
