<div class="box-border">
  <div class="row content-align">
    <div class="col-xs-12 text-content">{{widgetName}}</div>
  </div>
  <div class="row content-align">

    <div class="text-center"><h2 style="margin-left: 65px; color: rgb(209, 209, 209);">{{widgetData}}</h2></div>

  </div>
  <div class="custom-row content-align">
    <div class="col widget-footer-left widget-footer" [ngStyle]="{'background-color':leftFooterName === 'OK' ? 'green' : 'red' }">
      {{leftFooterName}}
    </div>
    <div class="col widget-footer-right widget-footer">
      <!-- <img *ngIf="rightFooterName==='UP'" src="assets/images/up_green.png" alt="Up" class="trending-image"/>
      <img *ngIf="rightFooterName!='UP'"src="assets/images/down_red.png" alt="Down" class="trending-image"/> -->
    </div>
  </div>
</div>

