import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  HostListener,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlantTwinDialogComponent } from '../../shared/plant-twin-dialog/plant-twin-dialog.component';
import { Observable, Subscription, interval } from 'rxjs';
import { DataService } from '../data.service';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-swh-operation-summary',
  templateUrl: './swh-operation-summary.component.html',
  styleUrls: ['./swh-operation-summary.component.scss']
})
export class SWHOperationSummaryComponent implements OnInit {
  displayedColumns: string[] = [
    'VendorId',
    'VendorName',
    'Location',
    'OpenTickets',
    'TotalTickets',
  ];
  @ViewChild('paginator') paginator: MatPaginator;
  @Output() nextPageEvent = new EventEmitter<{
    offset: string;
    limit: string;
  }>();
  dataSourceTotalSize: any;
  showWidgets = false;
  OPERATION_SUMMARY_LIST: OperationSummary[] = [];
  TOP_POWER_LIST: PowerConsumption[] = [];
  ASSETS_STATUS_LIST: AssetsStatus[] = [];
  public barChartInput: any[] = [];
  public lineChartInput: any[] = [];
  isPieLoad: boolean = false;
  isParetoLoaded: boolean = false;
  loadingSpin: boolean = false;
  apiUrl: string =
    environment.coreApiBaseUrl + environment.operationSummaryEndPoint;
  apiUrlTopPowerConsumptions: string =
    environment.coreApiBaseUrl + environment.swhAvgConsumption;
  apiUrlOnlineOfflineStatus: string =
    environment.coreApiBaseUrl + environment.onlineOfflineStatus;
  fetchAll = environment.coreApiBaseUrl + environment.swhOperationsSummary;
  totalOnlineAssets: number = 0;
  totalOfflineAssets: number = 0;
  avgMaintSpend: string;
  avgMaintTime: string;
  serviceTicketsOpen: string;
  private updateSubscription: Subscription;
  @Input() screenName: string;
  isOperationSummary: any;
  inputPieGridData: any;
  inputPieGridData1: any;
  inputPieGridStock: any;
  inputPieGridConsumed: any;
  maintenancePlanned: any;
  maintenanceBreakdown: any;
  maintenancePredictive: any;
  label: string = 'Total';
  public colors: any[] = [
    {
      to: 30,
      color: '#e44d25',
    },
    {
      from: 25,
      to: 100,
      color: 'green',
    },
  ];
  data: any;
  dataSource: MatTableDataSource<unknown>;
  currentUserRole: string;
  timeoutConfig: string;
  duration: any;

  constructor(
    public dialog: MatDialog,
    private service: DataService,
    private authService: MsalService,
    private http: HttpClient,
    private auth: MsalService,
    private cd: ChangeDetectorRef
  ) { }
  openDialog() {
    this.dialog.open(PlantTwinDialogComponent);
  }
  innerWidth: any;
  innerHeight: any;
  height;
  width;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth - 200;
    this.innerHeight = window.innerHeight - 150;
  }
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 641 && this.innerWidth <= 961) {
      this.height = '100';
      this.width = '100';
    } else if (this.innerWidth > 961 && this.innerWidth <= 1025) {
      this.height = '90';
      this.width = '90';
    } else {
      this.height = '110';
      this.width = '110';
    }
  }
  ngOnInit(): void {
    if (localStorage.getItem('slideshow') === null) {
      this.timeoutConfig = localStorage.getItem('slideshowlist');
    } else {
      this.timeoutConfig = localStorage.getItem('slideshow');
    }
    this.parseConfiguration(JSON.parse(this.timeoutConfig));
    this.currentUserRole = localStorage.getItem('role');
    if (this.currentUserRole === 'TV User') {
      setTimeout(() => {
        if (this.showWidgets) {
          this.showWidgets = false;
        } else {
          this.showWidgets = true;
        }
      }, this.duration * 1000 - 2000);
    }
    this.onOrientationChange();
    this.onResize();
    this.label = 'Total(hrs)';
    this.isOperationSummary = true;
    this.service.setData(this.isOperationSummary);
    this.getOperationSummary();
    setTimeout(() => this.loadData(), 2000);
    this.topPowerConsumptions();
    this.assetsStatus();
  }
  getOperationSummary() {
    this.getData().subscribe((res) => {
      this.isPieLoad = true;
      this.data = res;
      this.data['operations'].forEach(element => {
        this.avgMaintSpend = '$' + element.avgMaintenanceSpend;
        this.avgMaintTime = element.avgMaintenanceTime + ' Hrs';
        this.serviceTicketsOpen = element.serviceTicketNew;
        this.maintenancePlanned = element.maintenancePlanned;
        this.maintenanceBreakdown = element.maintenanceBreakdown;
        this.maintenancePredictive = element.maintenancePredictive;
      });
    });
  }

  showCustomerInfo() {
    if (this.showWidgets) {
      this.showWidgets = false;
    } else {
      this.showWidgets = true;
    }
  }

  parseConfiguration(config) {
    config.forEach((element) => {
      if (element.screen === 'Polar Delight') {
        this.duration = element.duration;
      }
    });
  }

  pageChanged(event) {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    // this.alertTable = new AlertTableComponent(null,this.http,null,null);
    //this.alertTable.pageChanged(event);
    this.nextPageEvent.emit({
      offset: pageIndex.toString(),
      limit: pageSize.toString(),
    });
  }
  loadData() {
    this.getOperationSummaryDetails().subscribe(
      (response) => {
        this.isPieLoad = true;

        response.operations.forEach((element) => {
          //console.log("Data: " + JSON.stringify(element));
          this.OPERATION_SUMMARY_LIST.push(element);
        });
        //console.log("this.OPERATION_SUMMARY_LIST: " + JSON.stringify(this.OPERATION_SUMMARY_LIST))
        this.OPERATION_SUMMARY_LIST.forEach((element) => {
          //this.totalOnlineAssets = element.assetsOnline;
          //this.totalOfflineAssets = element.assetsOffline;
          //  this.avgMaintSpent = "$"+element.avgMaintenanceSpend;
          //  this.avgMaintTime = element.avgMaintenanceTime + " Mins";
          //  this.serviceTicketsOpen = element.serviceTicketsOpen;
          this.inputPieGridData = element.uptime;
          this.inputPieGridData1 = element.downtime;
          this.inputPieGridStock = element.instock;
          this.inputPieGridConsumed = element.consumed;
          //  this.maintenancePlanned = this.data.PlannedPrcnt;
          //  this.maintenanceBreakdown = this.data.BreakdownPrcnt;
          //  this.maintenancePredictive = this.data.CorrectivePrcnt;
        });
      },
      (error) => {
        // this.isPieLoad = true;
        console.log('There Was A Problem');
      }
    );
  }

  getData(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.fetchAll, '', { headers: headers });
  }

  getOperationSummaryDetails(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrl, '', { headers: headers });
  }

  topPowerConsumptions() {
    this.loadingSpin = true;

    this.getTopPowerConsumptions().subscribe(
      (response) => {
        this.isParetoLoaded = true;

        response.forEach((element) => {
          //console.log("Data: " + JSON.stringify(element));
          this.TOP_POWER_LIST.push(element);
        });
        this.TOP_POWER_LIST.forEach((element) => {
          this.barChartInput.push({
            name: element.cityName,
            value: element.unitConsumption,
          });
          this.lineChartInput.push({
            name: element.cityName,
            value: element.consumptionPercentage,
          });
        });
        this.loadingSpin = false;
        hideloader();
        //console.log("Data: " + JSON.stringify(this.barChartInput))
      },
      (error) => {
        console.log('There Was A Problem');
      }
    );
    function hideloader() {
      //document.getElementById('loading').style.display = 'none';
    }
  }
  getTopPowerConsumptions(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrlTopPowerConsumptions, '', {
      headers: headers,
    });
  }
  assetsStatus() {
    this.loadingSpin = true;

    this.getAssetsStatus().subscribe(
      (response) => {
        response.forEach((element) => {
          //console.log("Data: " + JSON.stringify(element));
          this.ASSETS_STATUS_LIST.push(element);
        });
        this.ASSETS_STATUS_LIST.forEach((element) => {
          if (element.displayName == 'MachineStatus') {
            if (element.serverEnableFlag == true) {
              this.totalOnlineAssets = this.totalOnlineAssets + 1;
            } else {
              this.totalOfflineAssets = this.totalOfflineAssets + 1;
            }
          }
        });

        console.log('totalOnlineAssets: ' + this.totalOnlineAssets);
        console.log('totalOfflineAssets: ' + this.totalOfflineAssets);
      },
      (error) => {
        console.log('There Was A Problem');
      }
    );
  }
  getAssetsStatus(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrlOnlineOfflineStatus, '', {
      headers: headers,
    });
  }
}

export interface OperationSummary {
  Id: number;
  serviceTicketsOpen: string;
  avgMaintenanceSpend: string;
  avgMaintenanceTime: string;
  sparesInventoryInStock: string;
  maintenancePlanned: string;
  maintenanceBreakdown: string;
  maintenancePredictive: string;
  powerConsumption: string;
  availabilityUptime: string;
  availabilityDownTime: string;
  assetsOnline: string;
  assetsOffline: string;
  uptime: string;
  downtime: string;
  instock: string;
  consumed: string;
  freeFld1: string;
  freeFld2: string;
  freeFld3: string;
  RModTime: string;
  RCreTime: string;
}
export interface PowerConsumption {
  cityName: string;
  unitConsumption: number;
  consumptionPercentage: number;
}
export interface AssetsStatus {
  displayName: string;
  folder: string;
  serverEnableFlag: boolean;
  value: number;
}

