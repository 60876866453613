<ngx-charts-pie-chart
  [view]="view"
  [scheme]="colorScheme"
  [results]="inputPieData"
  [gradient]="gradient"
  [legend]="showLegend"
  [legendPosition]="legendPosition"
  [labels]="showLabels"
  [doughnut]="isDoughnut"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  >
</ngx-charts-pie-chart>
