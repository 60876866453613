import { Component, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
    history.pushState(null, null, location.href);
    fromEvent(window, 'popstate').subscribe(_ => {
      history.pushState(null, null, location.href);
   });
  }

}
