<div class="container" style="max-width: 94%;width: 100%;">
    <div class="row">
      <div class="col-md-2 mt-2">
        <div class="row">
          <img src="assets/images/water-heater.png" alt="Polar Delight" class="img-machine" />
        </div>
        <div class="col-sm-12" >
          <div class="assetIdDropdn">
            <div class="form-group">
              <label for="demo_overview">Asset</label>
                <select class="form-control" data-role="select-dropdown" (change)="onChnageOfAsset($event)">
                <option *ngFor="let asset of assetIdList" [value]='asset.id' [attr.selected]="asset.assetId==assetId ? '' : null">
                    {{asset}}
                </option>
                
              </select>
            </div>
          </div>
          <ul class="image-attributes" style="margin-bottom: 0;padding:0;">
             <p>Asset Id : {{cncOperationData.assetId}}</p>
            <p>Location : {{cncOperationData.assetLocation}}</p>
          </ul>
        </div>
        <div class="row">
          <button class="btn btn-primary digitalTwnBtn">
            <a (click)="openDialog()">Digital Twin</a>
          </button>
          
        </div>
      </div>
      <div class="col-md-10 mt-2">
        <div class="row">
          <div class="col">
            <app-custom-number-card [cardFooterColor]="cncOperationData.machineStatus === 'Online' ? 'green' : 'darkred'"
              [attribute]="'Machine Status'" [attributeValue]="cncOperationData.machineStatus" [imageName]=""
              [screenName]="'operation'"></app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Machine Code'"
              [attributeValue]="cncOperationData.assetId" [imageName]="" [screenName]="'operation'">
            </app-custom-number-card>
  
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
              [attribute]="'Leak Detection'" [attributeValue]="cncOperationData.leakDetection" [imageName]=""
              [screenName]="'operation'"></app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
              [attribute]="'Capacity'" [attributeValue]="cncOperationData.capacity+' L'" [imageName]=""
              [screenName]="'operation'"></app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
              [attribute]="'Hot Water Recovery time'" [attributeValue]="cncOperationData.hotWaterRecoveryTime+' min'" [imageName]=""
              [screenName]="'operation'"></app-custom-number-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <app-gauge-chart-widget [widgetData]="cncOperationData.assetHealthData.assetHealth"
              [widgetName]="'Asset Health'" 
              [needleValue]="cncOperationData.assetHealthData.needleValue">
            </app-gauge-chart-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-gauge-chart-widget [widgetData]="cncOperationData.timeHeatingPeakEnergy.TimeHeatingPeakEnergy+' KW'"
              [widgetName]="'Time Heating Peak Energy'" [min]="cncOperationData.timeHeatingPeakEnergy.min+' KW'"
              [max]="cncOperationData.timeHeatingPeakEnergy.max+' KW'"
              [needleValue]="cncOperationData.timeHeatingPeakEnergy.needleValue"></app-gauge-chart-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-gauge-chart-widget [widgetData]="cncOperationData.HotWaterTemperature.hotWaterTemperature+' °F'"
              [widgetName]="'Hot Water Temperature'" [min]="cncOperationData.HotWaterTemperature.min+' °F'"
              [max]="cncOperationData.HotWaterTemperature.max+' °F'"
              [needleValue]="cncOperationData.HotWaterTemperature.needleValue"></app-gauge-chart-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-gauge-chart-widget [widgetData]="cncOperationData.setTemperature.SetTemperature+' °F'"
              [widgetName]="'Set Temperature'" [min]="cncOperationData.setTemperature.min+' °F'"
              [max]="cncOperationData.setTemperature.max+' °F'" [needleValue]="cncOperationData.setTemperature.needleValue">
            </app-gauge-chart-widget>
          </div>
        </div>
        <div class="row" style="margin-top: -10px;">
          <div class="col-md-3">
            <app-card-widget [widgetData]="cncOperationData.waterUsage.WaterUsage+' L'" [widgetName]="'Water Usage'"
               [min]="cncOperationData.waterUsage.min+' L'"
              [max]="cncOperationData.waterUsage.max+' L'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="cncOperationData.AverageEnergy.averageEnergy+' KW'" [widgetName]="'Avg energy Usage/day'"
            [min]="cncOperationData.AverageEnergy.min+' KW'" [max]="cncOperationData.AverageEnergy.max+' KW'"
            ></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="cncOperationData.ActualCurrent.actualCurrent + ' A'"
              [widgetName]="'Actual Current'" 
              [min]="cncOperationData.ActualCurrent.min+' A'" [max]="cncOperationData.ActualCurrent.max+' A'">
            </app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="cncOperationData.Power.power+' KW'"
              [widgetName]="'Power'" 
              [min]="cncOperationData.Power.min+' KW'" [max]="cncOperationData.Power.max+' KW'">
            </app-card-widget>
          </div>
        </div>
      </div>
    </div>
  </div>