export var single = [
  {
    "name": "In Stock",
    "value": 80
  },
  {
    "name": "Consumed",
    "value": 30
  }
];
