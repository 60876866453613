import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-ev-device-list',
  templateUrl: './ev-device-list.component.html',
  styleUrls: ['./ev-device-list.component.css']
})
export class EvDeviceListComponent implements OnInit {
  apiUrl: string = environment.coreApiBaseUrl + environment.fetchAllVehiclesEndPoint
  updateVehicleStatusUrl: string = environment.coreApiBaseUrl + environment.updateVehicleStatusEndPoint
  VEHICLE_LIST: VehicleList[] = [];
  dataSource;
  selectedAsset:any[]=[];
  deviceListResponse: any;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<VehicleList>(true, []);
  simulatedDeviceStatus: string;
  sumilatedReadyFlag: boolean = false;

  displayedColumns: string[] = ['id', 'address', 'latitude','longitude','vehicleName','status'];
  ngAfterViewInit() {

  }

  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.populateVehicleList();
  }
  populateVehicleList() {
    this.getVehicleListResponse().subscribe(
      (response) => {
        response.forEach(element => {
          this.VEHICLE_LIST.push(element);
        });
        this.dataSource = new MatTableDataSource(this.VEHICLE_LIST);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.log("There Was A Problem While Getting Vehicle")
      });
  }
  getVehicleListResponse(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrl, "", { 'headers': headers })
  }
  updateCheckedList(event, element){
    event ? this.selection.toggle(element) : null;
    const item = {"vehicleName": element.vehicleName , "status": event.checked};
    this.selectedAsset.push(item);
  }
  updateStatusFlag(){
    this.simulatedDeviceStatus = '';
   this.updateStatus(this.selectedAsset).subscribe(
    (response) => {
      //console.log("response:"+response)
      this.sumilatedReadyFlag=true;
      this.simulatedDeviceStatus="***Status Updated For Vehicle(s), Please Stop Simulator and Upload a Valid CSV File***";

    });
  }
  updateStatus(assets): Observable<any>{
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken
    }
    return this.http.post(this.updateVehicleStatusUrl, assets, { 'headers': headers })

  }

}
export interface VehicleList {
  id: number;
  address: string;
  latitude: string;
  longitude: string;
  status: boolean;
  vehicleName: string;
}
