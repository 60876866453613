import { Component, OnInit } from '@angular/core';
import { single } from './horizontal-bar-data.js';

@Component({
  selector: 'app-ngx-horizontal-bar',
  templateUrl: './ngx-horizontal-bar.component.html',
  styleUrls: ['./ngx-horizontal-bar.component.css']
})
export class NgxHorizontalBarComponent implements OnInit {

  single: any[];
  view: any[] = [830,250];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  
  
  colorScheme = {
    domain: ['#085f01', '#e0f7fa', '#C7B42C', '#AAAAAA', '#A10A28', '#5AA454']
  };

  constructor() {
    Object.assign(this, { single });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngOnInit(): void {
  }

}
