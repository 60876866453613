export var bubbleData =  [
  {
    name: 'Event 1',
    series: [
      {
        name: '2014',
        x: '2014',
        y: 60.3,
        r: 90.4
      },
      {
        name: '2014',
        x: '2014',
        y: 80.3,
        r: 78
      },
      {
        name: '2015',
        x: '2015',
        y: 25.4,
        r: 59
      }
    ]
  },
  {
    name: 'Event 2',
    series: [
      {
        name: '2016',
        x: '2016',
        y: 88.8,
        r: 410
      },
      {
        name: '2017',
        x: '2017',
        y: 46.9,
        r: 983
      },
      {
        name: '2018',
        x: '2018',
        y: 45.4,
        r: 253
      }
    ]
  },
  {
    name: 'Event 3',
    series: [
      {
        name: '2016',
        x: '2016',
        y: 31.4,
        r: 63
      },
      {
        name: '2019',
        x: '2019',
        y: 79.1,
        r: 79.4
      },
      {
        name: '2019',
        x: '2019',
        y: 77.2,
        r: 56.9
      }
    ]
  },
  {
    name: 'Event 4',
    series: [
      {
        name: '2015',
        x: '2015',
        y: 80.2,
        r: 62.7
      },
      {
        name: '2016',
        x: '2016',
        y: 77.8,
        r: 58.9
      },
      {
        name: '2017',
        x: '2017',
        y: 75.7,
        r: 57.1
      }
    ]
  },
  {
    name: 'Event 5',
    series: [
      {
        name: '2019',
        x: '2019',
        y: 80.2,
        r: 72.7
      },
      {
        name: '2019',
        x: '2019',
        y: 77.8,
        r: 58.9
      },
      {
        name: '2019',
        x: '2019',
        y: 65.7,
        r: 97.1
      }
    ]
  }
];
