import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

export interface CriticalEvents {
  Id: number;
  AlertName: string;
  Value: number;
  AssetName: string;
  rcretime: Date;
}

@Component({
  selector: 'app-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.css'],
})
export class EventTableComponent implements OnInit {
  Id;
  CRITICAL_EVENT_DATA: CriticalEvents[] = [];
  dataSource;
  dataSourceTotalSize : any;
  apiUrl: string = environment.coreApiBaseUrl + environment.allCriticalEventsEndPoint;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('FormTemplate') FormTemplate: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;
  loadingSpin: boolean = false;
  public modalRef: BsModalRef;
  config = {
    animated: false,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-sm center-modal',
  };

  displayedColumns: string[] = [
    'Id',
    'AlertName',
    'Value',
    'AssetName',
    'rcretime',
    'details',
    'update',
  ];
  assetName: any;

  ngAfterViewInit() {}

  constructor(
    private authService: MsalService,
    private http: HttpClient,
    private auth: MsalService,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.loadingSpin = true;
    this.getEvents('0', '5');
  }
  getEvents(offset, limit) {
    this.loadingSpin = true;
    this.CRITICAL_EVENT_DATA = [];
    this.getAllCriticalEvents(offset, limit).subscribe(
      (response) => {
        // this.deviceListResponse = JSON.stringify(response);
        // console.log("Device List Response: " + JSON.stringify(response.events))
        response.events.content.forEach((element) => {
          //console.log("Data: " + JSON.stringify(element));
          this.CRITICAL_EVENT_DATA.push(element);
        });
        console.log("length: " + response.events.total)
        this.dataSourceTotalSize = response.events.total;
        // console.log("Device List: "+this.CRITICAL_EVENT_DATA);
        this.dataSource = new MatTableDataSource(this.CRITICAL_EVENT_DATA);
        // this.dataSource.paginator = this.paginator;
        // //this.dataSource.paginator._intl.itemsPerPageLabel ='';
        // this.dataSource.sort = this.sort;
        // const sortState: Sort = { active: 'rcretime', direction: 'desc' };
        // this.sort.active = sortState.active;
        // this.sort.direction = sortState.direction;
        // this.sort.sortChange.emit(sortState);
        let table=document.getElementById('criticlTicktsTbl');
        table.querySelector('tbody').style.display='revert';
        this.loadingSpin = false;
      },
      (error) => {
        console.log('There Was A Problem Getting All Critical Events');
        this.loadingSpin = false;
      }
    );
  }
  getAllCriticalEvents(offset, limit): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', offset);
    params = params.set('size', limit);
    params = params.set('sort', 'rcretime,desc');

    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken
    };
    return this.http.post(this.apiUrl+'?'+params.toString(), '', { headers: headers });
  }
  pageChanged(event){
    let table=document.getElementById('criticlTicktsTbl');
    table.querySelector('tbody').style.display='none';
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;

    let previousSize = pageSize * pageIndex;

    this.getEvents((pageIndex).toString(), pageSize.toString());
  }

  public redirectToDetails(element){
    this.Id = element.Id;
    this.assetName = element.AssetName;
    this.modalRef = this.modalService.show(this.FormTemplate, this.config);
  };

  public redirectToUpdate = (id: string) => {};

  public redirectToDelete = (id: string) => {};

  close() {
    this.modalRef.hide();
  }
}
