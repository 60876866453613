import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
import { environment } from '@env';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(public http: HttpClient) { }

    apiURL = environment.coreApiBaseUrl;

    concatUrl(url: string): string {
        return this.apiURL.concat(url);
    }

    get idToken(): string {
        return localStorage.getItem('msal.idtoken');
    }

    /**
     * http get method
     * @param url - url for http request
     * @param params it construct the url with given params
     */
    get(url: string, params?: any) {
        return this.http.get<any>(this.concatUrl(url), params)
            .pipe(
                share(),
                catchError(this.handleError)
            );
    }

    /**
    * http post method
    * @param url - url for http request
    * @param params it construct the url with given params
    */
    post(url: string, params?: any, header?: { headers: any }) {
        const headers = new HttpHeaders()
            .set('content-type', 'application/json')
            .set('Authorization', 'Bearer ' + this.idToken);

        // if there is some other header change or overwrite existing one
        if (header) {
            Object.keys(header.headers).forEach(key => {
                headers.set(key, header.headers[key]);
            });
        }

        return this.http.post<any>(this.apiURL.concat(url), params, { headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    /**
     * http put method
     * @param url - url for http request
     * @param params it construct the url with given params
     * update all data in particular document
     */
    put(url: string, params?: any) {
        return this.http
            .put(this.concatUrl(url), params)
            .pipe(
                catchError(this.handleError)
            );
    }

    /**
     * http delete method
     * @param url - url for http request
     * @param params it construct the url with given params
     * here params means sending particular field value to match and update (eg: {id:"1"})
     */
    delete(url: string, params?: any) {
        return this.http
            .delete(this.concatUrl(url), params)
            .pipe(
                catchError(this.handleError)
            );
    }

    // Error handling - NEED TO USE THE ERRORHANDLINGSERVICE
    private handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

}
