import { ChangeDetectorRef, Component, Input, OnInit,HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlantTwinDialogComponent } from '../../shared/plant-twin-dialog/plant-twin-dialog.component';
import { Observable,Subscription, interval  } from 'rxjs';
import {DataService} from '../data.service';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';

export interface TableData {
  id: string;
  alertName: string;
  value: string;
  assetName: string;
  time: string;
}

const table_data = [{'id': 1, 'alertName': 'Temperature', 'value': 12, 'assetName': 'CNC', 'time': '01-01-2022'},
{'id': 2, 'alertName': 'Temperature', 'value': 12, 'assetName': 'CNC', 'time': '01-01-2022'}]

@Component({
  selector: 'app-operations-cnc',
  templateUrl: './operations-cnc.component.html',
  styleUrls: ['./operations-cnc.component.scss']
})
export class OperationsCncComponent implements OnInit {
  Table_data: TableData[] = [];
  dataSource;
  displayedColumns: string[] = [
    'ID',
    'AlertName',
    'Value',
    'AssetName',
    'Time'
  ];
  public showLabels = true;
    public showTicks = true;
    public reverse = false;
    public value = 1500;
    public listItems: Array<string> = ['round', 'butt'];

    public startAngle = 0;
    public endAngle = 180;
    public rangeSize = 5;

    public rangeLineCap = 'round';

    public rangePlaceholderColor = 'whitesmoke';
    public ticksColor = 'whitesmoke';
    public labelsColor = 'whitesmoke';
  OPERATION_SUMMARY_LIST: OperationSummary[] = [];
  TOP_POWER_LIST: PowerConsumption[] = [];
  ASSETS_STATUS_LIST: AssetsStatus[] = [];
  public barChartInput: any[]=[];
  public lineChartInput: any[]=[];
  isPieLoad: boolean = false;
  isParetoLoaded: boolean = false;
  loadingSpin : boolean = false;
  apiUrl: string = environment.coreApiBaseUrl + environment.cncOperationSummary;
  apiUrlTopPowerConsumptions: string = environment.coreApiBaseUrl + environment.cncPowerConsumption;
  apiUrlOnlineOfflineStatus: string = environment.coreApiBaseUrl + environment.cncGetAssetStatusUrl;
  cncComputedData: string = environment.coreApiBaseUrl + environment.cncComputedData;
  cncRawDataUrl: string = environment.coreApiBaseUrl + environment.cncRawData;
  totalOnlineAssets: number = 0;
  totalOfflineAssets: number = 0;
  avgMaintSpent: string;
  avgMaintTime: string;
  serviceTicketsOpen: string;
  private updateSubscription: Subscription;
  @Input() screenName: string
  isOperationSummary: any;
  inputPieGridData: any;
  inputPieGridData1: any;
  inputPieGridStock: any;
  inputPieGridConsumed: any;
  maintenancePlanned: any;
  maintenanceBreakdown: any;
  maintenancePredictive: any;
  label: string = 'Total'
  public colors: any[] = [{
    to: 30,
    color: '#e44d25'
  }, {
    from: 25,
    to: 100,
    color: 'green'
  }];
  cncCompData: any;
  downtime: any;
  mttr: any;
  mtbf: any;
  oeeper_day: any;
  cncRawData: any;
  production: any;
  workOrder: any;
  totalOnlineMachine: any;
  totalOfflineMachine: any;
  failures: any;

  constructor(public dialog: MatDialog,private service:DataService, private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) { }
  openDialog(){
    this.dialog.open(PlantTwinDialogComponent);
  }
  innerWidth:any;
  innerHeight:any;
  height;
  width;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth-200;
    this.innerHeight = window.innerHeight-150;
  }
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    if(this.innerWidth > 641 && this.innerWidth <= 961){
      this.height = '100';
      this.width = '100';
    }
    else if( this.innerWidth > 961 && this.innerWidth <= 1025){
      this.height = '90';
      this.width = '90';
    }
    else{
      this.height = '110';
      this.width = '110';
    }
  }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(table_data);
    this.onOrientationChange();
    this.onResize();
    this.label = 'Total(hrs)'
    this.isOperationSummary = true;
    this.service.setData(this.isOperationSummary);
    this.getOperationSummary();
    this.topPowerConsumptions();
    this.assetsStatus();
    this.getComputedData();
    this.getRawData();
  }
  getOperationSummary(){

    this.getOperationSummaryDetails().subscribe(
      (response) => {
          this.isPieLoad = true;

          response.operations.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.OPERATION_SUMMARY_LIST.push(element);

        });
        //console.log("Data: " + JSON.stringify(this.OPERATION_SUMMARY_LIST))
        this.parseResponse(this.OPERATION_SUMMARY_LIST)

      },
      (error) => {
       // this.isPieLoad = true;
        console.log("There Was A Problem")
      });

  }
  parseResponse(summaryList){
     summaryList.forEach(element => {
       this.workOrder = element.workOrder;
      //  this.totalOnlineMachine = element.assetsOnline;
      //  this.totalOfflineMachine = element.assetsOffline;
       this.avgMaintSpent = "$"+element.avgMaintenanceSpend;
       this.avgMaintTime = element.avgMaintenanceTime + " Mins";
       this.serviceTicketsOpen = element.serviceTicketNew;
       this.inputPieGridData = element.availabilityUptime;
       this.inputPieGridData1 = element.availabilityDownTime;
       this.inputPieGridStock = element.instock;
       this.inputPieGridConsumed = element.consumed;
      //  this.maintenancePlanned = element.maintenancePlanned;
      //  this.maintenanceBreakdown = element.maintenanceBreakdown;
      //  this.maintenancePredictive = element.maintenancePredictive;
     });
  }

  getComputedData() {
this.getCncComputedDetails().subscribe((res) => {
this.cncCompData = res[0];
this.downtime = this.cncCompData['downtime'];
this.mttr = this.cncCompData['mttrper_day'];
this.mtbf = this.cncCompData['mtbfper_day'];
this.oeeper_day = this.cncCompData['oeeper_day'];
})
  }

  getRawData() {
this.getCncRawDetails().subscribe((res) => {
  this.cncRawData = res;
  this.production = this.cncRawData[1]['value'];
  this.failures = this.cncRawData[0]['value'];
})
  }

  getOperationSummaryDetails(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrl, "", { 'headers': headers })
  }

  getCncComputedDetails(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.cncComputedData, "", { 'headers': headers })
  }

  getCncRawDetails(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.cncRawDataUrl, "", { 'headers': headers })
  }

  topPowerConsumptions(){
    this.loadingSpin=true;

    this.getTopPowerConsumptions().subscribe(
      (response) => {

          this.isParetoLoaded = true;

          response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.TOP_POWER_LIST.push(element);

        });
        //this.barChartInput = [{"name":"Plant_1","value":28},{"name":"Pant_2","value":19},{"name":"Plant_3","value":16},{"name":"Plant_4","value":10},{"name":"Plant_5","value":5},{"name":"Plant_6","value":2}];
        this.TOP_POWER_LIST.forEach(element =>{
           this.barChartInput.push({name:element.plantName, value: element.unitConsumption});
           this.lineChartInput.push({name:element.plantName, value: element.consumptionPercentage});
        });
        this.loadingSpin=false;hideloader();
        console.log("bar chart Data: " + JSON.stringify(this.barChartInput))

      },
      (error) => {

        console.log("There Was A Problem")
      });
      function hideloader() {
        //document.getElementById('loading').style.display = 'none';
    }
  }
  getTopPowerConsumptions(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrlTopPowerConsumptions, "", { 'headers': headers })
  }
  assetsStatus(){
    this.loadingSpin=true;

    this.getAssetsStatus().subscribe(
      (response) => {

           response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.ASSETS_STATUS_LIST.push(element);

        });
        this.ASSETS_STATUS_LIST.forEach(element =>{
        if(element.displayName == 'MachineStatus' ){
          if(element.serverEnableFlag == true){
            this.totalOnlineAssets =  this.totalOnlineAssets + 1;
          }else{
           this.totalOfflineAssets = this.totalOfflineAssets + 1;
          }
        }
        
       });
       
        console.log("totalOnlineAssets: " + this.totalOnlineAssets)
        console.log("totalOfflineAssets: " + this.totalOfflineAssets)

      },
      (error) => {

        console.log("There Was A Problem")
      });
     
  }
  getAssetsStatus(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrlOnlineOfflineStatus, "", { 'headers': headers })
  }
}


export interface OperationSummary {
 	Id :  number;
	serviceTicketsOpen:  string;
	avgMaintenanceSpend: string;
  avgMaintenanceTime: string;
	sparesInventoryInStock: string;
	maintenancePlanned: string;
  maintenanceBreakdown: string;
  maintenancePredictive : string;
  powerConsumption: string;
	availabilityUptime: string;
	availabilityDownTime: string;
	assetsOnline: string;
	assetsOffline: string;
	uptime: string;
	downtime: string;
	instock: string;
	consumed: string;
	freeFld1: string;
	freeFld2: string;
	freeFld3: string;
	RModTime: string;
	RCreTime: string;
 }
 export interface PowerConsumption {
  plantName: string;
  unitConsumption: number;
  consumptionPercentage: number;
 }
 export interface AssetsStatus {
  displayName: string;
  folder: string;
  serverEnableFlag: boolean;
  value: number;
 }
