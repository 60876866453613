<div class="container" style="width: 100%;max-width: 94%;">
  <div class="row row-style">
    <div class="ipad-portrait col-md-8" style="background-color:#222222; border-radius: 18px;">
      <app-event-table></app-event-table>
    </div>
    <div class="col-md-4">
      <div class="box-border" style="border-radius: 18px;">
        <div class="row">
          <div class="col-xs-12 top-header">Overall Ticket Status</div>
        </div>
        <div *ngIf="isLoadTicketStatus" class="chart-content">
          <kendo-chart class="chart-style" [style.height.px]="180" [transitions]="false">
            <kendo-chart-series>
              <kendo-chart-series-item [autoFit]="autofit" type="donut" [size]="30" [data]="data" categoryField="kind"
                field="share">
                <kendo-chart-series-item-labels position="outsideEnd" color="#000" [content]="labelContent">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend [labels]="{font:'8pt sans-serif'}" [visible]="true" [position]="position"
              [orientation]="orientation" [offsetX]="10" [offsetY]="10"
              data-bind="events: { legendItemClick: disableEvent, legendItemHover: disableEvent } ">
            </kendo-chart-legend>
          </kendo-chart>
        </div>
        <div *ngIf="loadingSpinInPie" class="d-flex justify-content-center">
          <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
        </div>
      </div>
    </div>
    <div class="row row-style" style="margin-left: 0; margin-right: 0;width: 100%;">
      <div class="ipad-portrait col-md-8" style="background-color:#222222; border-radius: 18px;">
        <div style="margin-top: 4px;margin-bottom: -5px;text-align: center;">
          <p style="font-size: 11pt; color:  rgb(209, 209, 209); text-align: left;display: inline;margin-left: 15px;">
            Service Tickets</p>
        </div>
        <div>
          <app-reusable-app-table [tableData]="serviceTicketData" [columnHeader]="serviceTicketTableHeader"
            [dataSourceTotalSize]="dataSourceTotalSize"
            (filterStatus)="filterStatusChange($event)"
            [statusAll]="statusTypes"
            present="true"
            (nextPageEvent)="pageChangedOnClick($event)"
            (isSyncRequired)="isSyncRequired($event)"
            (eventDescOnClick)="onDetailClick($event)">
          </app-reusable-app-table>
          <p style="text-align:center; margin-top: 65px; " >{{statusmessage}}</p>
        </div>
        <div *ngIf="loadingSpin" class="d-flex justify-content-center">
          <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="box-border" style="border-radius: 18px;">
          <div class="row mb-1" style="margin-bottom: -7px;">
            <div class="col-xs-12 top-header">Top Ticket Insights</div>
          </div>
          <mat-tab-group mat-align-tabs="center">
            <ng-container *ngFor="let tab of tabsData">
              <mat-tab [label]="tab?.label">
                <div class="card-block">
                  <p class="card-title">{{tab?.title}}</p>
                  <p class="card-text"><br>
                    <ng-container *ngFor="let data of tab?.description?.data; let idx=index;">
                      <span *ngIf="tab?.description?.list?.style">{{idx + 1}}.&nbsp;</span>
                      <span>{{data?.label}}</span>
                      <br />
                    </ng-container>
                  </p>
                </div>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
