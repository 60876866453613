<!-- <mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[10,20]" showFirstLastButtons></mat-paginator> -->
<table class="table-container" mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}}</td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>
    <ng-container matColumnDef="latitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Latitude </th>
        <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
      </ng-container>
      <ng-container matColumnDef="longitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitude </th>
        <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
      </ng-container>
      <ng-container matColumnDef="vehicleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> VehicleName </th>
        <td mat-cell *matCellDef="let element"> {{element.vehicleName}} </td>
      </ng-container>
    
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element; let i=index">
        <mat-checkbox (change)="updateCheckedList($event, element)" 
                      [checked]="element.status"></mat-checkbox>
      </td>
    </ng-container>
   

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <br>
  <button class="mat-focus-indicator mat-raised-button mat-button-base mat-primary ng-star-inserted" style="margin-bottom: 5px;" (click)="updateStatusFlag()">Update</button>
  <p *ngIf="sumilatedReadyFlag" style="align-items: center;">{{simulatedDeviceStatus}} </p>
  
  
