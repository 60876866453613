import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse } from 'msal';
import TsiClient from "tsiclient";
import { environment } from '@env';

@Component({
  selector: 'app-real-time-feed',
  templateUrl: './real-time-feed.component.html',
  styleUrls: ['./real-time-feed.component.css']
})
export class RealTimeFeedComponent implements OnInit, OnDestroy {

  // initiate auth objects, header, and login modal
  tsiClient = new TsiClient();
  startDate;
  endDate;
  validBucketSizes;
  bucketSize;
  // lineChart = new TsiClient();

  accessToken: string;
  timeSeriesScope = ["https://api.timeseries.azure.com//user_impersonation"];
  isAutoRefreshTriggered: Boolean = false;
  authContext: any;

  //Autorefresh interval is 10 seconds
  autoRefreshPeriod = environment.tsiRealTimeFeedAutoRefreshPeriodInSeconds * 1000;
  interval: any;

  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {

    this.auth.acquireTokenSilent({ scopes: this.timeSeriesScope })
      .then((response: AuthResponse) => {
        this.accessToken = response.accessToken;
        //console.log(this.accessToken);
      }).catch(err => {
        console.log(err);
      }).then(_ => {
        this.onLoadChartContents();
      });

  }


  ngOnInit(): void {
    let tsqExpressions;
    if (localStorage.getItem('tsqExpressions')) tsqExpressions = JSON.parse(localStorage.getItem('tsqExpressions'));
    this.startDate = tsqExpressions? new Date((tsqExpressions[0].searchSpan.from)).valueOf()- 1000 * 60 * 60 * 4 : new Date(new Date()).valueOf() - 1000 * 60 * 60 * 4;
    this.endDate = tsqExpressions? new Date((tsqExpressions[0].searchSpan.to)).valueOf() + 1000 * 5 : new Date(new Date()).valueOf() + 1000 * 5;
    this.auth.acquireTokenSilent({ scopes: this.timeSeriesScope })
      .then((response: AuthResponse) => {
        this.accessToken = response.accessToken;
        //console.log(this.accessToken);
      }).catch(err => {
        console.log(err);
      }).then(_ => {
        this.onLoadChartContents();
        this.reloadChart();
      });

      this.validBucketSizes = this.getValidBucketSizes(this.startDate, this.endDate);
      this.bucketSize = this.getDimensionAndIntegerForRangeAndBuckets(this.startDate, this.endDate, 100);
  };

  onAutoRefreshToggle(event: MatSlideToggleChange) {
    console.log('toggle', event.checked);
    if (event.checked) {
      this.startAutoRefresh();
    } else {
      this.stopAutoRefresh();
    }
  }
  startAutoRefresh() {
    this.interval = setInterval(() => {
      this.startDate = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 4);
      console.log(this.startDate.toISOString());
      this.endDate = new Date((new Date()).valueOf() + 1000 * 5);
      this.reloadChart();
    }, this.autoRefreshPeriod);

  }

  stopAutoRefresh() {
    clearInterval(this.interval);
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 1));
    console.log(this.startDate.toISOString());
    this.endDate = new Date();
    this.reloadChart();
  }

  reloadChart() {
    this.getResults(undefined, undefined, undefined);
  }

  //Utility functions for determining bucket size when you adjust the timeframe
  validTimeIntervals = (() => {
    let validTimeIntervals = [];
    for (let i = 1; i < 1000; i++) {
      if (1000 % i === 0) {
        validTimeIntervals.push(i + 'ms');
      }
    }
    for (let i = 1; i < 60; i++) {
      if (60 % i === 0) {
        validTimeIntervals.push(i + 's');
      }
    }
    for (let i = 1; i < 60; i++) {
      if (60 % i === 0) {
        validTimeIntervals.push(i + 'm');
      }
    }
    for (let i = 1; i < 24; i++) {
      if (24 % i === 0) {
        validTimeIntervals.push(i + 'h');
      }
    }
    for (let i = 1; i < 8; i++) {
      validTimeIntervals.push(i + 'd');
    }
    return validTimeIntervals;
  })();

  getDimensionAndIntegerForRangeAndBuckets = (zoomMin, zoomMax, targetBuckets) => {
    let timeRangeInMillis = Math.max(zoomMax - zoomMin, 1);
    let bucketSizeInMillis = Math.ceil(timeRangeInMillis / targetBuckets);
    let int, dim;
    if (bucketSizeInMillis < 1000) {
      dim = 'ms';
      int = bucketSizeInMillis;
    }
    else if (bucketSizeInMillis < 1000 * 60) {
      dim = 's';
      int = Math.ceil(bucketSizeInMillis / 1000);
    }
    else if (bucketSizeInMillis < 1000 * 60 * 60) {
      dim = 'm';
      int = Math.ceil(bucketSizeInMillis / (1000 * 60));
    }
    else if (bucketSizeInMillis < 1000 * 60 * 60 * 24) {
      dim = 'h';
      int = Math.ceil(bucketSizeInMillis / (1000 * 60 * 60));
    }
    else {
      dim = 'd';
      int = Math.ceil(bucketSizeInMillis / (1000 * 60 * 60 * 24));
    }

    // round to next smallest interval that is a valid interval
    let idx = -1;
    while (idx === -1) {
      idx = this.validTimeIntervals.indexOf(int + dim);
      if (idx === -1) {
        int--;
      }
    }

    return this.validTimeIntervals[idx];
  }

  getValidBucketSizes = (fromMillis, toMillis) => {
    let minIntAndDim = this.getDimensionAndIntegerForRangeAndBuckets(fromMillis, toMillis, 1000);
    let maxIntAndDim = this.getDimensionAndIntegerForRangeAndBuckets(fromMillis, toMillis, 1);
    let minIdx = this.validTimeIntervals.indexOf(minIntAndDim);
    let maxIdx = this.validTimeIntervals.indexOf(maxIntAndDim);
    return this.validTimeIntervals.slice(minIdx, maxIdx + 1);
  }
  getNewBucketSize = (oldValidBucketSizes, newValidBucketSizes, oldBucketSize) => {
    let prevPercentile = (oldBucketSize && oldValidBucketSizes.indexOf(oldBucketSize) !== -1) ? (oldValidBucketSizes.indexOf(oldBucketSize)) / oldValidBucketSizes.length : .1;
    let newBucketIndex = Math.max(Math.min(Math.round(prevPercentile * newValidBucketSizes.length), newValidBucketSizes.length - 1), 0);
    return newValidBucketSizes[newBucketIndex];
  }

  // create tsq expressions, they are PAYG SKU query objects
  // startDate = new Date('2017-04-14T13:00:00Z');
  // endDate = new Date(this.startDate.valueOf() + 1000 * 60 * 60 * 24);
  tsqExpressions = [];
  colors = ['#3195E3', '#D869CB', '#FF8C00', '#8FE6D7', '#008272', '#F7727E', '#E0349E', '#C8E139', '#60B9AE',
    '#93CFFB', '#854CC7', '#258225', '#0078D7', '#FF2828', '#FFF100'];
  currentTsqECount = 0;

  brushActions = [
    {
      name: "Zoom",
      action: (from, to) => {
        this.getResults(new Date(from), new Date(to), undefined);
        this.availabilityChart.setBrush(new Date(from), new Date(to));
      }
    },
    {
      name: "Explore Events",
      action: (fromTime, toTime) => {
        let _ = this;
        let visibilityState = this.lineChart.getVisibilityState();
        let visibleTsqEs = [];
        this.tsqExpressions.forEach((tsqE, idx) => {
          let visibilityStateForQuery = visibilityState[idx][Object.keys(visibilityState[idx])[0]];
          if (visibilityStateForQuery[0]) {
            visibleTsqEs.push(tsqE);
          }
        });
        this.getTsiToken().then(function (token) {
          document.getElementById('eventsWrapper').style.transform = 'translateX(0)';
          _.tsiClient.server.getTsqResults(token, localStorage.getItem('tsiEnvironmentId'), _.getLtsExploreEventsPayload(visibleTsqEs)).then(function (events) {
            var transformedEvents = _.tsiClient.ux.transformTsqResultsToEventsArray(events, {});
            var eventsTable = _.tsiClient.ux.EventsTable(document.getElementById('events'));
            eventsTable.render(transformedEvents, { theme: 'dark', offset: "Local" }, true);
          });
        });
      }
    }
  ];

  availabilityChart: any;
  lineChart: any;
  // get results from server and render them in a line chart
  getResults = (from, to, bs) => {
    let _ = this;
    if (!from && !to) {
      from = this.startDate;
      to = this.endDate;
    }
    if (from && to && !bs) {
      var newBucketSizes = this.getValidBucketSizes(from.valueOf(), to.valueOf());
      var newBucketSize = this.getNewBucketSize(this.validBucketSizes, newBucketSizes, this.bucketSize);
      if (this.tsqExpressions.length == 0) {
        let previousSelctdData = [];
        previousSelctdData.push(JSON.parse(localStorage.getItem('tsqExpressions')));
        previousSelctdData[0].forEach(chartItem => {
          let tsqExp_instance = new this.tsiClient.ux.TsqExpression({}, {});
          let finaltsqExpressions = Object.setPrototypeOf(chartItem, tsqExp_instance['__proto__']);
          this.tsqExpressions.push(finaltsqExpressions);
        });
      }

      this.tsqExpressions.forEach(tsqe => { tsqe.searchSpan.from = from; tsqe.searchSpan.to = to, tsqe.searchSpan.bucketSize = newBucketSize });
      this.tsqExpressions.forEach((tsqe, i) => {
        tsqe.contextMenu = [{
          name: 'Remove this series',
          action: () => {
            this.tsqExpressions.splice(i, 1);
            this.getResults(undefined, undefined, undefined);
          }
        }]
      })
      this.renderIntervalSlider(from, to, newBucketSizes, newBucketSize);

    }
    else {
      this.tsqExpressions.forEach(tsqe => { tsqe.searchSpan.bucketSize = bs });
      this.bucketSize = bs;
    }
    localStorage.setItem('tsqExpressions', JSON.stringify(this.tsqExpressions)); // Setting TsqExp in localStorage
    this.getTsiToken().then(function (token) {
      _.tsiClient.server.getTsqResults(token, localStorage.getItem('tsiEnvironmentId'), _.tsqExpressions.map(function (tsqe) { return tsqe.toTsq(true) })).then(function (result) {
        var transformedResult = _.tsiClient.ux.transformTsqResultsForVisualization(result, _.tsqExpressions);
        _.lineChart.render(transformedResult, { theme: 'dark', legend: 'compact', grid: true, tooltip: true, brushContextMenuActions: _.brushActions, autoTriggerBrushContextMenu: true, offset: 'Local', color: '#136BFB', includeEnvelope: true, variableAlias: 'Prakash' }, _.tsqExpressions);
      });
    });
    this.startDate = from;
    this.endDate = to;
  }

  // interval slider
  intervalSlider: any;

  renderIntervalSlider = (from, to, newValidBucketSizes, newBucketSize) => {
    this.validBucketSizes = newValidBucketSizes;
    this.bucketSize = newBucketSize;
    this.intervalSlider.render(this.validBucketSizes.map(n => ({ label: n.toString(), action: () => this.getResults(null, null, n) })), { theme: 'dark', throttleSlider: true }, 300, this.bucketSize);
  }

  getLtsExploreEventsPayload = (queries) => {
    var fromTime = this.startDate;
    var toTime = this.endDate;
    var maxEvents = 5000;
    let tsqArray = queries.map(q => q.toTsq(true, true));
    tsqArray.forEach((tsq, i) => { tsq.getEvents.searchSpan.from = fromTime; tsq.getEvents.searchSpan.to = toTime; tsq.getEvents.take = (Math.floor(maxEvents / tsqArray.length) + (i < maxEvents % tsqArray.length ? 1 : 0)) });
    return tsqArray;
  }

  onLoadChartContents(): void {


    this.availabilityChart = new this.tsiClient.ux.AvailabilityChart(document.getElementById('availability'));

    this.lineChart = new this.tsiClient.ux.LineChart(document.getElementById('chart1'));

    // interval slider
    this.intervalSlider = new this.tsiClient.ux.Slider(document.getElementById('intervalSlider'));
    this.renderIntervalSlider(this.startDate, this.endDate, this.validBucketSizes, this.bucketSize);

    var onInstanceClick = function (instance) {
      _.toggleClass('ADD');
      let contextMenuActions = [];
      var eventsTsqExpressions = [new _.tsiClient.ux.TsqExpression(
        { timeSeriesId: [instance.timeSeriesId[0]] }, // instance json
        {
          Avg: {

          }
        }, // variable json
        { from: _.startDate, to: _.endDate, bucketSize: '1m' }, // search span
        '#D869CB', // color
        'AvgValue')];
      _.getTsiToken().then(token => {
        _.tsiClient.server.getTsqResults(token, localStorage.getItem('tsiEnvironmentId'), eventsTsqExpressions.map(function (ae) { return ae.toTsq(true, true) })).then(r => {
          console.log(r);
          if (r[0].properties && Array.isArray(r[0].properties)) {
            r[0].properties.forEach((t) => {
              let type = t.timeSeriesType;
              // Object.keys(type.variables).forEach((vName) => {
              //   let option = {};
              //   if (type.variables[vName].aggregation.tsx === 'avg($value)') {
              //     let newType = _.getTsmTypeFromVariable(type.variables[vName]);
              //     option['name'] = vName;
              //     option['action'] = () => addInstanceAction(newType, vName, instance);
              //   } else {
              //     option['name'] = vName;
              //     option['action'] = () => addInstanceAction(instance.type, vName, instance);
              //   }
              //   contextMenuActions.push(option);
              // });
              let option = {};
              option['name'] = t.name;
              option['action'] = () => addInstanceAction(t.type, t.name, instance);
              contextMenuActions.push(option);
            });
            hierarchy.drawContextMenu(contextMenuActions, {});
            _.toggleClass('REMOVE');
          }
        })
      });
    }

    let _ = this;
    // get availability, then get results
    var hierarchy;
    this.getTsiToken().then(function (token) {
      _.tsiClient.server.getAvailability(token, localStorage.getItem('tsiEnvironmentId'), "?api-version=2018-11-01-preview").then(function (result) {
        var result = result['availability']
        _.availabilityChart.render(_.tsiClient.ux.transformAvailabilityForVisualization(result, 500),
          { theme: 'dark', color: '#136BFB', legend: 'hidden', brushMoveEndAction: (from, to) => { _.getResults(from, to, undefined) } },
          result);
        _.endDate = new Date(result.range.to);
        _.startDate = new Date(_.endDate.valueOf() - 1000 * 60 * 60 * 24);
        hierarchy = new _.tsiClient.ux.HierarchyNavigation(document.getElementById('hierarchy'));
        hierarchy.render(localStorage.getItem('tsiEnvironmentId'), _.getTsiToken, { theme: 'dark', onInstanceClick: instance => onInstanceClick(instance) })
      })
    })

    var addInstanceAction = (type, vName, instance) => {
      let stripHits = s => s.split('<hit>').join('').split('</hit>').join('');
      if (instance.highlights) {
        instance.instanceFields = instance.highlights.instanceFieldNames.reduce((p, c, i) => {
          if (i in instance.highlights.instanceFieldValues) {
            p[stripHits(c)] = stripHits(instance.highlights.instanceFieldValues[i]);
          }
          return p;
        },
          {});
      }
      instance.description = stripHits(instance.highlights.description);
      instance.name = stripHits(instance.highlights.name);
      addInstanceAsQuery(instance, type, instance.name, vName);
    };

    var addInstanceAsQuery = (instance, type, nodeName, variableName) => {
      let tsq, variableObject, chartDataOptions = {};
      // if (type.variables.avg && type.variables.avg.aggregation && type.variables.avg.aggregation.tsx === 'avg($value)') {
      //   variableObject = type.variables;
      // } else {
      //   variableObject = { [variableName]: type.variables[variableName] };
      // }
      let event = '$event.' + variableName + '.' + type;
      variableObject = {
        Max: {
          kind: 'numeric',
          value: { tsx: event },
          filter: null,
          aggregation: { tsx: 'max($value)' }
        }

      };
      console.log(instance);
      let alias = instance.name ? instance.name : instance.timeSeriesId.join(',') + ' - ' + variableName;
      chartDataOptions['alias'] = alias;
      chartDataOptions['color'] = this.colors[this.currentTsqECount];
      chartDataOptions['includeEnvelope'] = true;
      this.currentTsqECount++;
      var tsqExp = new this.tsiClient.ux.TsqExpression(instance, variableObject, {}, chartDataOptions);
      this.tsqExpressions.push(tsqExp);
      this.getResults(undefined, undefined, undefined);
    }




  }

  getTsiToken = () => {
    return new Promise(
      (resolve, reject) => {
        try {
          resolve(this.accessToken);
        } catch (error) {
          reject('Error');
        }
      });
  }

  toggleClass(type) {
    let element = document.getElementById('hierarchy');
    type === 'ADD' ? element.classList.add('disabled') : element.classList.remove('disabled');
  }

  getTsmTypeFromVariable = (v) => {
    let type = {
      variables: {
        avg:
        {
          kind: 'numeric',
          value: v.value,
          filter: v.filter ? v.filter : null,
          aggregation: {
            tsx: 'avg($value)'
          }
        }
        ,
        min:
        {
          kind: 'numeric',
          value: v.value,
          filter: v.filter ? v.filter : null,
          aggregation: {
            tsx: 'min($value)'
          }
        }
        ,
        max:
        {
          kind: 'numeric',
          value: v.value,
          filter: v.filter ? v.filter : null,
          aggregation: {
            tsx: 'max($value)'
          }
        }
      }
    };
    return type;
  }

  hideEvents() {
    document.getElementById('eventsWrapper').style.transform = 'translateX(100%)';
    setTimeout(function () {
      document.getElementById('events').innerHTML = '';
    }, 400);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

}
