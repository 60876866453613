<ngx-charts-area-chart-stacked
  [view]="view"
  [scheme]="colorScheme"
  [legend]="legend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [timeline]="timeline"
  [results]="multi"
  (select)="onSelect($event)">
</ngx-charts-area-chart-stacked>
