import { ChangeDetectorRef,Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table';
import { ReusableAppTableComponent } from '../reusable-app-table/reusable-app-table.component';
import { OPCCommonService } from '../../services/opc-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-inline-editable-table',
  templateUrl: './inline-editable-table.component.html',
  styleUrls: ['./inline-editable-table.component.css']
})
export class InlineEditableTableComponent implements OnInit {
  apiUrl: string = environment.coreApiBaseUrl + environment.retrieveAssetInfoEndPoint
  updateAssetInfoUrl : string = environment.coreApiBaseUrl + environment.updateAssetInfoEndPoint
  dataSource;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(ReusableAppTableComponent)
  enableConstant: boolean = false;
  showTable: boolean=false;
  selectedAsset: string
  assetUpdateInfoMessage : string ='';
  displayedColumns: string[] = ['id', 'displayname', 'value', 'type','simfrequency','rangemin','rangemax','serverEnable'];
  DEVICE_INFO: SelectedDevicesInfo[] = [];
  selection = new SelectionModel<SelectedDevicesInfo>(true, []);
  onLoadMessage: string = "** Select An Asset To Edit. Asset Parameters Will Appear Here ** "
    constructor(private router: Router,
      private route: ActivatedRoute, private service: OPCCommonService,private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {

    }


    ngOnInit(): void {
      this.service.currentData.subscribe(data => {
        this.selectedAsset = data;
        this.DEVICE_INFO =[];
      //  console.log("Yahoo Got Data: "+this.selectedAsset);

        if(this.selectedAsset != ''){
          this.onLoadMessage = ''
          this.populateAssetInfo(this.selectedAsset);
        }

      });

    }


    populateAssetInfo(assetName) {
     //console.log("Request Fired");
      this.getAssetInfoResponse(assetName).subscribe(
        (response) => {
          response.forEach(element => {
           // console.log("Value: "+element.value)
            if(element.value == '')
               element.type='Random'
               else element.type='Constant'
          this.DEVICE_INFO.push(element);
          });
        //  console.log("Device List: "+JSON.stringify(this.DEVICE_INFO));
          this.dataSource = new MatTableDataSource(this.DEVICE_INFO);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        (error) => {
          console.log("There Was A Problem While Fetching Asset Parameters")
        });
    }
    getAssetInfoResponse(assetName): Observable<any> {
      let idToken = localStorage.getItem('msal.idtoken');
      const headers = {
        'content-type': 'application/json',
        'Authorization':'Bearer '+idToken

      }
      return this.http.post(this.apiUrl, assetName, { 'headers': headers })
    }

    selectOption(selectedValue: string){
     console.log("target.value: "+selectedValue);
     if(selectedValue === "Constant"){
      this.enableConstant = true;
     }else if(selectedValue === "Random"){
      this.enableConstant = false;
     }
     console.log("enableContant: "+this.enableConstant)
    }

    updateAssetInfo(){
      console.log(JSON.stringify(this.DEVICE_INFO));
      this.assetUpdateInfoMessage='';
      this.getUpadateAssetInfoResponse(this.DEVICE_INFO).subscribe(
        (response) => {
          console.log("Asset Info Updated")
          this.assetUpdateInfoMessage='***Asset Info Updated***';
        },
        (error) => {
          console.log("There Was A Problem Occured While Updating Param")
          this.assetUpdateInfoMessage='***Error Occured While Asset Info Updated***';
        });
    }
    getUpadateAssetInfoResponse(assetInfoList): Observable<any> {
      let idToken = localStorage.getItem('msal.idtoken');
      const headers = {
        'content-type': 'application/json',
        'Authorization':'Bearer '+idToken

      }
      return this.http.post(this.updateAssetInfoUrl, assetInfoList, { 'headers': headers })
    }

    updateCheckedList(event, element){
   //   event ? this.selection.toggle(element) : null;
      element['serverEnable'] = event.checked;
    }
}
export interface SelectedDevicesInfo {
  id: number;
  displayname: string;
  value: string;
  simfrequency: string;
  rangemin : string;
  rangemax: String;
  serverEnable: boolean;
  type: string;
 }
