<ngx-charts-gauge
[view]="view"
[legend]="showLegend"
[legendPosition]="legendPosition"
[results]="gaugeInputData"
[textValue]="gaugeTextValue"
[scheme]="colorScheme"
[min]="gaugeMin"
[max]="gaugeMax"
[units]="gaugeUnits"
[angleSpan]="gaugeAngleSpan"
[startAngle]="gaugeStartAngle"
[showAxis]="gaugeShowAxis"
[bigSegments]="gaugeLargeSegments"
[smallSegments]="gaugeSmallSegments"
  >
</ngx-charts-gauge>
