import { getTreeNoValidDataSourceError } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';



@Component({
  selector: 'app-pareto-chart',
  templateUrl: './pareto-chart.component.html',
  styleUrls: ['./pareto-chart.component.css']
})
export class ParetoChartComponent  {
  name = 'Angular   6';
  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart;

  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d');

    let myChart = new Chart(this.ctx, {
      type: 'bar',
      data: {
          datasets: [{
              label: 'Machine Issue',
              data: [19, 16, 11, 28,5,3],
              backgroundColor: "blue",
              // this dataset is drawn below
              order: 2
              
              
          }, {
              
              data: [10, 15, 20, 25,30,35],
              type: 'line',
              backgroundColor: "white",
              fill:"false",
              borderColor: "#8e5ea2",
              // this dataset is drawn on top
              order: 1
          }],
          labels: ['Unit out of service- Not dispencing', 'Low product warning', 'Humidity sensor near threshold', 'Cup stuck','Dispense pressure','freezing temperature High/Low']
      },
      options: null
   });
  }
}
