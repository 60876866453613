<form [formGroup]="form" (ngSubmit)="submit()">

    <div class="form-group">
        <label for="deviceId">Device ID</label>
        <input formControlName="deviceId" id="deviceId" type="text" class="form-control">
        <div *ngIf="f.deviceId.touched && f.deviceId.invalid" class="alert alert-danger">
            <div *ngIf="f.deviceId.errors.required">Device ID is required.</div>
            <div *ngIf="f.deviceId.errors.minlength">Device ID should be 3 character.</div>
        </div>
    </div>
    <div class="form-group">
        <label for="nodeId">Node ID</label>
        <input formControlName="nodeId" id="nodeId" type="text" class="form-control">
        <div *ngIf="f.nodeId.touched && f.nodeId.invalid" class="alert alert-danger">
            <div *ngIf="f.nodeId.errors.required">Node ID is required.</div>
            <div *ngIf="f.nodeId.errors.minlength">Node ID should be 3 character.</div>
        </div>
    </div>
    <div class="form-group">
        <label for="browseName">Browse Name</label>
        <input formControlName="browseName" id="browseName" type="text" class="form-control">
        <div *ngIf="f.browseName.touched && f.browseName.invalid" class="alert alert-danger">
            <div *ngIf="f.browseName.errors.required">Browse Name is required.</div>
            <div *ngIf="f.browseName.errors.minlength">Browse Name should be 3 character.</div>
        </div>
    </div>
    <div class="form-group">
        <label for="simFrequency">Simulation Frequency</label>
        <input formControlName="simFrequency" id="simFrequency" type="text" class="form-control">
        <div *ngIf="f.simFrequency.touched && f.simFrequency.invalid" class="alert alert-danger">
            <div *ngIf="f.simFrequency.errors.required">Browse Name is required.</div>
            <div *ngIf="f.simFrequency.errors.minlength">Browse Name should be 3 character.</div>
        </div>
    </div>
    <!-- <div class="form-group">
        
              <label for="random">Random</label>
                <input formControlName="random" id="random" type="radio" class="form-control" style="width: 25%;">
                <label class="radio-inline" for="random">Constant</label>
                <input formControlName="constant" id="constant" type="radio" class="form-control" style="width: 25%;">
                <div *ngIf="f.random.touched && f.random.invalid" class="alert alert-danger">
                    <div *ngIf="f.random.errors.required">Random or Contant is required.</div>
                </div>
          
    </div> -->
    <div class="custom-control custom-switch custom-control-inline">
        <input [disabled]="showConstant" type="checkbox" class="custom-control-input" id="customSwitch1"
            (change)=showRandomData($event)>
        <label class="custom-control-label" for="customSwitch1">Random</label>
    </div>
    <div class="custom-control custom-switch custom-control-inline">
        <input [disabled]="showRandom" type="checkbox" class="custom-control-input" id="customSwitch2"
            (change)=showConstantData($event)>
        <label class="custom-control-label" for="customSwitch2">Constant</label>
    </div>
    <div *ngIf="showRandom" id="randomForm">
        <!-- <label class="mr-sm-2 sr-only" for="inlineFormCustomSelect">Data Type</label>
        <select class="custom-select mr-sm-2" id="inlineFormCustomSelect">
            <option selected>Data Type...</option>
            <option value="1">Int64</option>
            <option value="2">Byte</option>
            <option value="3">String</option>
        </select> -->
        <div id="datatype">
            <label class="mr-sm-2 sr-only" for="inlineFormCustomSelectdatatype">Data Type</label>
            <select class="custom-select mr-sm-2" id="inlineFormCustomSelectdatatype" formControlName="datatype">
                <option selected>Data Type...</option>
                <option *ngFor="let dataType of dataTypes">{{dataType}}</option>
            </select>
        </div>

        <div class="form-group">
            <label for="randomValue">Value</label>
            <input formControlName="randomValue" id="randomValue" type="text" class="form-control" />

            <div *ngIf="f.randomValue.touched && f.randomValue.invalid" class="alert alert-danger">
                <div *ngIf="f.randomValue.errors.required">Value is required.</div>
            </div>
        </div>
        <div class="form-group">
            <label for="minRange">Minimum Range</label>
            <input formControlName="minRange" id="minRange" type="text" class="form-control" />

            <div *ngIf="f.minRange.touched && f.minRange.invalid" class="alert alert-danger">
                <div *ngIf="f.minRange.errors.required">Value is required.</div>
            </div>
        </div>
        <div class="form-group">
            <label for="maxRange">Maximum Range</label>
            <input formControlName="maxRange" id="maxRange" type="text" class="form-control" />

            <div *ngIf="f.maxRange.touched && f.maxRange.invalid" class="alert alert-danger">
                <div *ngIf="f.maxRange.errors.required">Value is required.</div>
            </div>
        </div>
    </div>
    <div *ngIf="showConstant" id="constantForm">
        <div class="form-group">
            <label for="constantValue">Constant Value</label>
            <input formControlName="constantValue"  id="constantValue" type="text" value="Test" class="form-control" />
            <div *ngIf="f.constantValue.touched && f.constantValue.invalid" class="alert alert-danger">
                <div *ngIf="f.constantValue.errors.required">Constant Value is required.</div>
            </div>
        </div>
    </div>
    <div>
    <button class="btn btn-info btn-info1" type="submit" (click)="addAsset()">Add Device</button>&nbsp;
    <button class="btn btn-info btn-info2" type="button" (click)="resetValue()">Reset Value</button>
    </div>
    <p>{{successResponse}}</p>
    <p>{{errorResponse}}</p>
</form>