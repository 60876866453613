import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mat-contact-card',
  templateUrl: './mat-contact-card.component.html',
  styleUrls: ['./mat-contact-card.component.css']
})
export class MatContactCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
