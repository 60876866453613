import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { EvLoginComponent } from './components/ev-login/ev-login.component';
import { CncComponent } from './components/feature/cnc/cnc.component';
import { DiagnosticsComponent } from './components/feature/diagnostics/diagnostics.component';
import { ElectricVehicleComponent } from './components/feature/electric-vehicle/electric-vehicle.component';
import { OpcSimulatorComponent } from './components/feature/opc-simulator/opc-simulator.component';
import { PlantPerformanceComponent } from './components/feature/plant-performance/plant-performance.component';
import { PolarDelightTwinComponent } from './components/feature/polar-delight-twin/polar-delight-twin.component';
import { PolarDelightComponent } from './components/feature/polar-delight/polar-delight.component';
import { RealTimeFeedComponent } from './components/feature/real-time-feed/real-time-feed.component';
import { SmartWaterHeaterComponent } from './components/feature/smart-water-heater/smart-water-heater.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SwhLoginComponent } from './components/swh-login/swh-login.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'ev', component: EvLoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'smart-water-heater', component: SwhLoginComponent },
  {
    path: 'home', component: HomeComponent, canActivate: [
      MsalGuard
    ], children: [
      {
        path: 'polar-delight',
        component: PolarDelightComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'cnc',
        component: CncComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'real-time-feed',
        component: RealTimeFeedComponent,
        canActivate: [
          MsalGuard
        ]
      },

      {
        path: 'opc-simulator',
        component: OpcSimulatorComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ev',
        component: ElectricVehicleComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'smart-water-heater',
        component: SmartWaterHeaterComponent,
        canActivate: [
          MsalGuard
        ]
      }
    ]
  },
  { path: '**', redirectTo: '/home/polar-delight' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
