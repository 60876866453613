<!-- <div style="align-content: center;"><p class="text-content">Inventory Count</p></div> -->
<!-- <ngx-charts-pie-grid
  [view]="view"
  [scheme]="colorScheme"
  [results]="inputPieGridData"
  [label]="label"
  (select)="onSelect($event)">
</ngx-charts-pie-grid> -->
<div class="row" style="margin-top: 1%;">
  <div class="col-sm-6 left-div" style="padding-left: 10px;">
    <kendo-circulargauge class="green-class" [value]="inputPieGridStock" [scale]="scale" [colors]="colors" [style.height.px]="height" [style.width.px]="width" >
      <ng-template kendoCircularGaugeCenterTemplate let-value="value" class="text-content">
          {{ value }}% <br/>
          <span *ngIf="screen === 'PD'">In Stock</span>
          <!-- <span *ngIf="screen === 'EV'">250 Charges</span> -->
      </ng-template>
    </kendo-circulargauge>
  </div>
  
  <div class="col-sm-6 left-div" style="padding-right: 10px;">
    <kendo-circulargauge class="red-class" [value]="inputPieGridConsumed" [scale]="scale" [colors]="colors" [style.height.px]="height" [style.width.px]="width">
      <ng-template kendoCircularGaugeCenterTemplate let-value="value" class="text-content">
          {{ value }}% <br/>	
          <span *ngIf="screen === 'PD'">Consumed</span>
          <!-- <span *ngIf="screen === 'EV'">500 Charges</span> -->
      </ng-template>
    </kendo-circulargauge>
  </div>
</div>
<div class="row" *ngIf="screen === 'PD'">
  <div class="col-sm-6 text-right" >Total: 80</div>
  <div class="col-sm-6 text-left">Total: 30</div>
</div>
<div class="row" *ngIf="screen === 'EV'">
  <div class="col-sm-6 text-right" >Below 250 Charges</div>
  <div class="col-sm-6 text-left">Above 500 Charges</div>
</div>
