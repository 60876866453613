import { Injectable } from "@angular/core";
import { environment } from "@env";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { HttpService } from "../../services/http.service";
import { PredictionPriority, PredictionProblemCauses, PredictionMachineIssues } from "./ev-predictions.model";

@Injectable()
export class EvPredictionsService {

    constructor(private httpService: HttpService) { }

    getPredictionRPN(): Observable<PredictionPriority[]> {
        // TODO: need to uncomment when implement with API
        // return this.httpService.post<PredictionPriority[]>(environment.predictionPriorityEndPoint);
        return of(PREDICTION_DATA)
            .pipe(
                delay(2 * 1000)
            );
    }

    getMachineIssues(): Observable<PredictionMachineIssues[]> {
        // return this.httpService.post(environment.predictionMachineIssuesEndPoint);
        return of(MACHINE_ISSUES)
            .pipe(
                delay(2 * 1000)
            );
    }

    getProblemCauses(): Observable<PredictionProblemCauses[]> {
        // return this.httpService.post(environment.predictionProblemCausesEndPoint);
        return of(PROBLEM_CAUSES)
            .pipe(
                delay(2 * 1000)
            );
    }

}

const PREDICTION_DATA: PredictionPriority[] = [
    {
        "name": "Battery temp out of range",
        "value": 63
    },
    {
        "name": "Power out of range",
        "value": 19
    },
    {
        "name": "Overcharging",
        "value": 50
    },
    {
        "name": "Mechanical wear & tear",
        "value": 18
    }
];

const MACHINE_ISSUES: PredictionMachineIssues[] = [
    {
        "cumulativePercentage": 27.7559,
        "name": "Battery temp out of range",
        "value": 5416
    },
    {
        "cumulativePercentage": 54.1639,
        "name": "Overcharging",
        "value": 4153
    },
    {
        "cumulativePercentage": 77.6662,
        "name": "Power out of range",
        "value": 4586
    },
    {
        "cumulativePercentage": 100,
        "name": "Mechanical wear & tear",
        "value": 3358
    }
]

const PROBLEM_CAUSES: PredictionProblemCauses[] = [
    {
        "name": "Battery temp out of range",
        "value": 50.35
    },
    {
        "name": "Power out of range",
        "value": 14.79
    },
    {
        "name": "Overcharging",
        "value": 20.2
    },
    {
        "name": "Mechanical wear & tear",
        "value": 14.66
    }
];