<div class="row">
    <div class="col-md-5">
      <div class="service-form">
        <div class="title-info">
          <p>Services</p>
        </div>
        <div class="fit-form-data">
          <app-services-form></app-services-form>
        </div>
      </div>
    </div>
   
  </div>
