import { ViewChild, ElementRef, AfterViewInit,ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-device-config',
  templateUrl: './device-config.component.html',
  styleUrls: ['./device-config.component.css']
})
export class DeviceConfigComponent implements OnInit {
  DEVICE_LIST: DevicesData[] = [];
  apiUrl: string = environment.coreApiBaseUrl + environment.fetchDistinctAssets;
  autoRefreshPeriod = environment.operationAutoRefreshPeriodInSeconds * 1000;
  apiUrlOpcStatus: string = environment.coreApiBaseUrl + environment.isSimulatorStatusEndPoint;
  status: any;
  previousStatus = 'Online';
  lastReportedTime: any;
  interval: any;
  apiIoTStartUrl: string = environment.coreApiBaseUrl + environment.IoTStartCommandEndPoint
  apiIoTStopUrl: string = environment.coreApiBaseUrl + environment.IoTStopCommandEndPoint
  deviceListResponse: any;
  opcTable1Header = {'Id': 'SL No.', 'folder':'Asset ID','serverEnable':'Status'};
  opcTable1Data : any;
  dataSourceTotalSize: any;
  isOnLoad: boolean= false;
  startServerResponse: string =''
  stopServerResponse: string =''
  loadingSpin : boolean = false;
  currentStatus: any;
  statusTypes:any= ['Online','Offline'];
  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {
    //this.dataSource = new MatTableDataSource(DEVICE_LIST);
  }


  ngOnInit(): void {
    this.populateDevicesList('0', '5');
    this.checkOpcSimulatorRunning();
  }
  checkOpcSimulatorRunning() {
    this.interval = setInterval(() => {
      this.checkOpcSimulatorStatus();
    }, this.autoRefreshPeriod);
  }
  checkOpcSimulatorStatus() {
    this.checkOpcSimulatorStatusResponse().subscribe(
      (response) => {
        response.forEach(element => {
          if(element.simType == 'OPC'){
            console.log('status: '+element.status);
            this.currentStatus = element.status;
            this.lastReportedTime = element.lastReportedTime;
          }
        });

      },
      (error) => {
        // this.isPieLoad = true;
        console.log('There Was A Problem');
      }
    );

    if (this.status === this.currentStatus) {
      console.log('not changed');
    } else {
      this.status = this.currentStatus;
      console.log('changed');
    }
  }
  checkOpcSimulatorStatusResponse(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrlOpcStatus, '', { headers: headers });
  }
  populateDevicesList(offset, limit) {
    this.loadingSpin = true;
    this.DEVICE_LIST = [];
    this.opcTable1Data = [];
    this.getDeviceListResponse(offset, limit).subscribe(
      (response) => {
        this.loadingSpin = false;

          response.assets.content.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          //if(element.folder !="CNC")
          this.DEVICE_LIST.push({Id: element.Id,folder: element.folder,serverEnable:(element.serverEnable ? 'Online' : 'Offline')});
          });
        this.opcTable1Data = this.DEVICE_LIST;
        this.dataSourceTotalSize = response.assets.total;
       // console.log("opcTable1Data:"+JSON.stringify(this.opcTable1Data))
        this.isOnLoad= true;
      },
      (error) => {

        console.log("There Was A Problem")
      });

  }
  getDeviceListResponse(offset, limit): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', offset);
    params = params.set('size', limit);
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrl+'?'+params.toString(), "", { 'headers': headers })
  }
  callIoTStart(){
    this.startServerResponse = ''
    this.stopServerResponse=''
          this.callIoTStartCommand().subscribe(
        (response) => {
          console.log("Resonse Received: "+response.response)
            if(response.response.message == "Executed direct method Start"){
              console.log("OPC Simulator Server Started Successfully")
              this.startServerResponse = "**OPC Simulator Server Started Successfully**"
            }else
            this.startServerResponse = "There is a problem while starting OPC Simulator Server"

        },
        (error) => {

          console.log("There Was A Problem")
        });
    }
  callIoTStartCommand(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiIoTStartUrl, "", { 'headers': headers })
  }

  callIoTStop(){
    this.stopServerResponse = ''
    this.startServerResponse=''
          this.callIoTStopCommand().subscribe(
        (response) => {
          console.log("Resonse Received: "+response.response)
            if(response.response.message == "Executed direct method Stop"){
              console.log("OPC Simulator Server Started Successfully")
              this.stopServerResponse = "**OPC Simulator Server Stopped Successfully**"
            }else
            this.stopServerResponse = "There is a problem while stopping OPC Simulator Server"

        },
        (error) => {

          console.log("There Was A Problem")
        });
    }
  callIoTStopCommand(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiIoTStopUrl, "", { 'headers': headers })
  }
  pageChangedOnClick(event){
    let pageIndex = event.offset;
    let pageSize = event.limit;
    this.populateDevicesList((pageIndex).toString(), pageSize.toString());
  }
}
export interface DevicesData {
  Id: number;
  folder: string;
  serverEnable:string;
 }



