import {
  Component,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '@env';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { HttpService } from '../../services/http.service';
import { delay } from "rxjs/operators";

export interface CriticalEvents {
  Id: number;
  AlertName: string;
  Value: number;
  AssetName: string;
  rcretime: Date;
}

@Component({
  selector: 'app-ev-event-table',
  templateUrl: './ev-event-table.component.html',
  styleUrls: ['./ev-event-table.component.css'],
})
export class EvEventTableComponent implements OnInit {
  Id: number;
  CRITICAL_EVENT_DATA: CriticalEvents[] = [];
  dataSource: MatTableDataSource<any>;
  dataSourceTotalSize : any;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('FormTemplate') FormTemplate: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;
  loadingSpin: boolean = false;
  public modalRef: BsModalRef;
  config = {
    animated: false,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-sm center-modal',
  };

  displayedColumns: string[] = [
    'Id',
    'AlertName',
    'Value',
    'AssetName',
    'rcretime',
    'details',
    'update',
  ];
  assetName: any;

  exportTableButton = [
    {
      format: 'txt',
      label: 'Text',
      class: 'mb5 ml15'
    },{
      format: 'xlsx',
      label: 'Excel',
      class: 'mb5'
    },{
      format: 'csv',
      label: 'CSV',
      class: 'mb5'
    }
  ]

  ngAfterViewInit() {}

  constructor(
    private http: HttpService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.loadingSpin = true;
    this.getEvents('0', '5');
  }
  getEvents(offset, limit) {
    this.loadingSpin = true;
    this.CRITICAL_EVENT_DATA = [];
    this.getAllCriticalEvents(offset, limit).subscribe(
      (response) => {
        // this.deviceListResponse = JSON.stringify(response);
        response.events.content.forEach((element) => {
          this.CRITICAL_EVENT_DATA.push(element);
        });
        this.dataSourceTotalSize = response.events.total;
        this.dataSource = new MatTableDataSource(this.CRITICAL_EVENT_DATA);
        // this.dataSource.paginator = this.paginator;
        // //this.dataSource.paginator._intl.itemsPerPageLabel ='';
        // this.dataSource.sort = this.sort;
        // const sortState: Sort = { active: 'rcretime', direction: 'desc' };
        // this.sort.active = sortState.active;
        // this.sort.direction = sortState.direction;
        // this.sort.sortChange.emit(sortState);
        let table=document.getElementById('criticlTicktsTbl');
        table.querySelector('tbody').style.display='revert';
        this.loadingSpin = false;
      },
      (error) => {
        console.log('There Was A Problem Getting All Critical Events');
        this.loadingSpin = false;
      }
    );
  }
  getAllCriticalEvents(offset, limit): Observable<any> {
    // TODO: Later uncomment this API code
    let params = new HttpParams();
    params = params.set('page', offset);
    params = params.set('size', limit);
    params = params.set('sort', 'rcretime,desc');
    return this.http.post(environment.evCriticalEventsEndPoint +'?'+params.toString());
    // return of(CRITICAL_EVENT_DATA_DEFAULT).pipe(delay(1 * 1000));
  }
  pageChanged(event){
    let table=document.getElementById('criticlTicktsTbl');
    table.querySelector('tbody').style.display='none';
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;

    let previousSize = pageSize * pageIndex;

    this.getEvents((pageIndex).toString(), pageSize.toString());
  }

  public redirectToDetails(element){
    return;
    this.Id = element.Id;
    this.assetName = element.AssetName;
    this.modalRef = this.modalService.show(this.FormTemplate, this.config);
  };

  public redirectToUpdate = (id: string) => {};

  public redirectToDelete = (id: string) => {};

  close() {
    this.modalRef.hide();
  }
}

const CRITICAL_EVENT_DATA_DEFAULT = {
  "events": {
    "total": 1,
    "pageable": {
      "size": 20,
      "sort": {
        "orders": []
      },
      "page": 0
    },
    "content": [
      {
        "rmodtime": "Sep 24, 2021 12:12:20 PM",
        "AlertName": "SERVICE-BREAKDOWN",
        "Value": "Battery Temperature Out Of Range",
        "Id": 2,
        "rcretime": "Sep 24, 2021 12:12:20 PM",
        "AssetId": "EV_01",
        "AssetName": "EV_01"
      }
    ]
  }
}

// const CRITICAL_EVENT_DATA_DEFAULT = {
//   "events": {
//     "total": 10,
//     "pageable": {
//       "size": 5,
//       "sort": {
//         "orders": [
//           {
//             "nullHandling": "NATIVE",
//             "ignoreCase": false,
//             "property": "rcretime",
//             "direction": "DESC"
//           }
//         ]
//       },
//       "page": 0
//     },
//     "content": [
//       {
//         "Freefld3": "",
//         "Freefld2": "",
//         "rmodtime": "Sep 6, 2021 6:08:12 PM",
//         "AlertName": "SERVICE-CORRECTIVE",
//         "Value": "Battery Temp Out of Range",
//         "Id": 106744,
//         "ErrorCode": "",
//         "rcretime": "Sep 6, 2021 6:08:12 PM",
//         "AssetId": "",
//         "AssetName": "EV_01",
//         "Freefld1": ""
//       },
//       {
//         "Freefld3": "",
//         "Freefld2": "",
//         "rmodtime": "Sep 6, 2021 5:50:29 PM",
//         "AlertName": "SERVICE-CORRECTIVE",
//         "Value": "Battery Temp Out of Range",
//         "Id": 106743,
//         "ErrorCode": "",
//         "rcretime": "Sep 6, 2021 5:50:29 PM",
//         "AssetId": "",
//         "AssetName": "EV_01",
//         "Freefld1": ""
//       },
//       {
//         "Freefld3": "",
//         "Freefld2": "",
//         "rmodtime": "Sep 6, 2021 5:36:34 PM",
//         "AlertName": "SERVICE-CORRECTIVE",
//         "Value": "Battery Temp Out of Range",
//         "Id": 106742,
//         "ErrorCode": "",
//         "rcretime": "Sep 6, 2021 5:36:34 PM",
//         "AssetId": "",
//         "AssetName": "EV_01",
//         "Freefld1": ""
//       },
//       {
//         "Freefld3": "",
//         "Freefld2": "",
//         "rmodtime": "Sep 6, 2021 5:32:13 PM",
//         "AlertName": "SERVICE-CORRECTIVE",
//         "Value": "Battery Temp Out of Range",
//         "Id": 106741,
//         "ErrorCode": "",
//         "rcretime": "Sep 6, 2021 5:32:13 PM",
//         "AssetId": "",
//         "AssetName": "EV_01",
//         "Freefld1": ""
//       },
//       {
//         "Freefld3": "",
//         "Freefld2": "",
//         "rmodtime": "Sep 6, 2021 5:31:41 PM",
//         "AlertName": "SERVICE-CORRECTIVE",
//         "Value": "Battery Temp Out of Range",
//         "Id": 106740,
//         "ErrorCode": "",
//         "rcretime": "Sep 6, 2021 5:31:41 PM",
//         "AssetId": "",
//         "AssetName": "EV_01",
//         "Freefld1": ""
//       }
//     ]
//   }
// }