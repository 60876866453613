import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ngx-number-card-pod',
  templateUrl: './ngx-number-card-pod.component.html',
  styleUrls: ['./ngx-number-card-pod.component.css']
})
export class NgxNumberCardPodComponent  {

  @Input() podCardData : any[];
  //single: any[];
  view: any[] = [220,100];

  colorScheme = {
    domain: ['#085f01', '085f01', '#085f01', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  cardColor: string = '#1b1a1a';

  constructor() {
    //Object.assign(this, { single });
  }

  onSelect(event) {
    console.log(event);
  }


}
