import { ChangeDetectorRef, Component, Input, OnInit,HostListener } from '@angular/core';
import { Observable,Subscription, interval  } from 'rxjs';
import {DataService} from '../data.service';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-predictions-cnc',
  templateUrl: './predictions-cnc.component.html',
  styleUrls: ['./predictions-cnc.component.css']
})
export class PredictionsCncComponent implements OnInit {

  @Input() screenName: string
  public barChartInput: any[]=[];
  public lineChartInput: any[]=[];
  public xAxisLabelName = 'Problem Cause';
  public yAxisLabelName = 'Count';

  innerWidth:any;
  innerHeight:any;
  height: number;
  offsetX: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth-200;
    this.innerHeight = window.innerHeight-150;
    if(window.innerWidth <= 768) {
      this.height = 330;
      this.offsetX = 3;
    } else if (window.innerWidth === 820) {
      this.height = 352;
      this.offsetX = 3;
    } else if (window.innerWidth < 1025) {
      this.height = 357;
      this.offsetX = -15;
    } else {
      this.height = 412;
      this.offsetX = -55;
    }
  }
  constructor(private service:DataService, private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) { }
  PRIORITY_LIST: PredictionPriority[] = [];
  PROBLEM_CAUSES_LIST: PredictionProblemCauses[] = [];
  MACHINE_ISSUES_LIST: PredictionMachineIssues[] = [];
  public data: any[]=[];
  isPriorityLoaded: boolean = false;
  isProblemsLoaded: boolean = false;
  isIssuesLoaded: boolean = false;
  widgetData: any;
  apiUrl: string = environment.coreApiBaseUrl + environment.cncMlRPN;
  apiUrlProblemCauses: string = environment.coreApiBaseUrl + environment.cncMlProblemCause;
  apiUrlMachineIssues: string = environment.coreApiBaseUrl + environment.cncMlMachineIssues;
  ngOnInit(): void {
    this.onResize();
    this.getPredictionPriority();
    this.getPredictionProblemCauses();
    this.getPredictionMachineIssues();
  }
  getPredictionPriority(){

    this.getPredictionRPN().subscribe(
      (response) => {
          this.isPriorityLoaded = true;

          response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.PRIORITY_LIST.push(element);

        });
        console.log("RNP: " + JSON.stringify(this.PRIORITY_LIST))
       },
      (error) => {
        console.log("There Was A Problem")
      });
  }
  getPredictionRPN(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken
    }
    return this.http.post(this.apiUrl, "", { 'headers': headers })
  }

  getPredictionProblemCauses(){
    this.getProblemCauses().subscribe(
      (response) => {
          this.isProblemsLoaded = true;

          response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.PROBLEM_CAUSES_LIST.push(element);

        });
       // console.log("Problems: " + JSON.stringify(this.PROBLEM_CAUSES_LIST))
        this.PROBLEM_CAUSES_LIST.forEach(element => {
          let value = element.value.toFixed(2)
          var temp = { kind: element.name, value: value }
          this.data.push(temp);
        });
       console.log("Causes: " + JSON.stringify(this.data))
      },
      (error) => {

        console.log("There Was A Problem")
      });

  }
  getProblemCauses(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrlProblemCauses, "", { 'headers': headers })
  }
  getPredictionMachineIssues(){
    this.getMachineIssues().subscribe(
      (response) => {
          this.isIssuesLoaded = true;

          response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.MACHINE_ISSUES_LIST.push(element);

        });
        //Sort the Bars of Chart based on the value of Bar
        this.MACHINE_ISSUES_LIST.sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0))
      //  console.log("Machine Issues: " + JSON.stringify(this.MACHINE_ISSUES_LIST))
       this.widgetData = this.MACHINE_ISSUES_LIST;
       console.log('this.MACHINE_ISSUES_LIST', this.MACHINE_ISSUES_LIST);
       this.MACHINE_ISSUES_LIST.forEach(element =>{
        this.barChartInput.push({name:element.name, value: element.value});
        this.lineChartInput.push({name:element.name, value: element.cumulativePercentage});
     });
      },
      (error) => {

        console.log("There Was A Problem")
      });

  }
  getMachineIssues(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrlMachineIssues, "", { 'headers': headers })
  }

  public autofit = true;
  // public data: any[] = [{ kind: "Feed tube is not properly installed", "value": "3.00" },
  // { kind: "Draw rate is set too fast(POD)", value: "1.0" },
  // { kind: "The viscosity control is set too cold", value: "3.00" },
  // { kind: "The product is too liquid", value: "24.00" },
  // { kind: "Circuit breaker OFF or blown fuse", value: "3.00" },
  // { kind: "Improper lubrication of the draw valve and draw valve O-rings", value: "10.00" },
  // { kind: "Draw rate is set too fast (RAM)", value: "1.00" },
  // { kind: "Freezer Temp out of range", value: "18.00" },
  // { kind: "Dispense force out of range", value: "5.00" },
  // { kind: "Dispense stalled", value: "8.00" },
  // { kind: "Unable to home", value: "5.00" },
  // ];

  public labelContent(e: any): string {
    return e.value + "%";
  }
}

export interface PredictionPriority {
  value: number;
  name: string;
}
export interface PredictionProblemCauses {
  value: number;
  name: string;
}
export interface PredictionMachineIssues {
  value: number;
  name: string;
  cumulativePercentage: number;
}

