<mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[5,10]" showFirstLastButtons
    [length]="dataSourceTotalSize" (page)="onChangePage($event)"></mat-paginator>
<table class="table-container" mat-table [dataSource]="dataSource" matSort id="serviceTcktTbl">
    
  <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(columnHeader)">
      <ng-container *ngIf ="columnHeader[tableData] != 'Status' ">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="margin:0px;"> {{columnHeader[tableData]}} </th>
            <td mat-cell *matCellDef="let element" style="cursor: pointer;" (click)="changeTab(element[tableData])">

                <span *ngIf="columnHeader[tableData] == 'Ticket ID' || columnHeader[tableData] == 'Alert ID'">
                    <a class="servceTkct">{{element[tableData]}}</a>
                </span>
                <span *ngIf="columnHeader[tableData] == 'Description'">
                    <span style="cursor: pointer;" (click)="changeTab(element[tableData])">{{element[tableData]}}</span>
                </span>
                <span *ngIf="tableData == 'serverEnable'"
                    [ngClass]="element[tableData] == 'Online' ? 'i-am-green-box' : 'i-am-red-box'">
                    {{element[tableData]}}
                </span>
                <span
                    *ngIf="columnHeader[tableData] != 'Description' && columnHeader[tableData] != 'Ticket ID' && tableData != 'serverEnable' && columnHeader[tableData] != 'Alert ID'">
                    {{element[tableData]}}
                </span>
            </td>
        </ng-container>
        <ng-container *ngIf ="columnHeader[tableData] == 'Status' " >
          <th mat-header-cell *matHeaderCellDef  style="margin:0px;"  > {{columnHeader[tableData]}}
            <i class="fa fa-filter " id="icon" aria-hidden="true" [matMenuTriggerFor]="status"     >
                <mat-menu #status="matMenu" class="selected" id="items"  >
                  <button mat-menu-item (click)="filterByStatus('All')" id="All">All</button>
                  <button mat-menu-item (click)="filterByStatus('Open')" id="Open">Open</button>
                  <button mat-menu-item (click)="filterByStatus('Closed')" id="Closed">Closed</button>
                  <button mat-menu-item (click)="filterByStatus('Scheduled')" id="Scheduled" >Scheduled</button>
                  <button mat-menu-item (click)="filterByStatus('In Progress')" id="In Progress" >In Progress</button>
                  <button mat-menu-item (click)="filterByStatus('Completed')" id="Completed" >Completed</button>
                </mat-menu>
            </i>
          </th>
          <td mat-cell *matCellDef="let element">
            <span >
                {{element[tableData]}}
            </span>
          </td>
       </ng-container>
       </ng-container>
    
    <ng-container *ngIf="columnHeader.sla == 'SLA'" matColumnDef="detailsButton">
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let element">
            <a class="btn btn-primary" (click)="redirectToDetails(element)">
                <i class="fa fa-eye" aria-hidden="true" style="color: rgb(255, 217, 0);"></i>
            </a>
        </td>
    </ng-container>

    <ng-container *ngIf="columnHeader.freeFld1 == 'Asset Name'" matColumnDef="detailsButton">
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let element">
            <a class="btn btn-primary" (click)="redirectToDetails(element)">
                <i class="fa fa-eye" aria-hidden="true" style="color: rgb(255, 217, 0);"></i>
            </a>
        </td>
    </ng-container>
    <ng-container *ngIf="columnHeader.folder == 'Asset ID'" matColumnDef="actionButton">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
        <td mat-cell *matCellDef="let element">
            <a class="btnClass btn btn-primary" (click)="editAssetParams(element)">
                <i class="fa fa-edit pl-1 ml-4"></i>
            </a>
        </td>
    </ng-container>
    <span *ngIf="columnHeader.sla == 'SLA'">
        <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader).concat(['detailsButton'])" style="height: 20px;">
        </tr>
        <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader).concat(['detailsButton']);"></tr>
    </span>
    <span *ngIf="columnHeader.freeFld1 == 'Asset Name'">
        <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader).concat(['detailsButton'])" style="height: 20px;">
        </tr>
        <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader).concat(['detailsButton']);"></tr>
    </span>
    <span *ngIf="columnHeader.folder == 'Asset ID'">
        <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader).concat(['actionButton'])" style="height: 20px;">
        </tr>
        <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader).concat(['actionButton']);"></tr>
    </span>
    <span
        *ngIf="columnHeader.sla != 'SLA' && columnHeader.folder != 'Asset ID' && columnHeader.freeFld1 != 'Asset Name'">
        <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader)" style="height: 20px;"></tr>
        <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader);"></tr>
    </span>
</table>
<!-- Popup Screens for diff Stages-->

<ng-template #FormTemplate>
    <div class="modal-header h4">
        Service Ticket Details
    </div>
    <div class="modal-body text-center border-left">
        <div class="modalPopupCard modalCancel">
            <div class="modalPopupCardBody card-body srvceTktModal">
                <p>Alert ID : {{servceTktPopupData[0].alertId}}</p>
                <p>Ticket ID : {{servceTktPopupData[0].ticketId}}</p>
                <p>Asset Name : {{servceTktPopupData[0].freeFld1}}</p>
                <p>Description : {{servceTktPopupData[0].details}}</p>
                <p>Created Time : {{servceTktPopupData[0].RCreTime}}</p>
                <p>Status : {{servceTktPopupData[0].status}}</p>
                <p>Modified Time : {{servceTktPopupData[0].RModTime}}</p>
                <p>SLA : {{servceTktPopupData[0].sla | date:'medium'}}</p>
                <p style="color:green;">Proposed Solution : {{servceTktPopupData[0]?.proposedSolution}}</p>
            </div>
            <div class="modal-footer">
                <div class="row float-right">
                    <button [disabled]="['Closed', 'Scheduled', 'Service Scheduled'].includes(servceTktPopupData[0].status)" type="button" class="btn btn-warning" (click)="navigationEvent('orderScren')"> Order &
                        Schedule</button>
                    <button type="button" class="ml-1 btn btn-info" (click)="close()"> Close</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #orderTemplate>
    <div class="modal-header h4">
        <div class="row">
          <div class="col-md-4">
        <a (click)="goback('serviceTkt')" class="goBack">
          <i class="fa fa-backward" aria-hidden="true"></i>Go Back
        </a>
      </div>
      <div class="col-md-8">
        Part Review and Availability
      </div>
      </div>
      </div>
    <div class="modal-body text-center border-left">
        <div class="modalPopupCard modalCancel">
            <div class="modalPopupCardBody card-body srvceTktModal">
                <table class="table table-bordered table-responsive ordersTbl">
                    <thead>
                        <tr>
                            <th *ngFor="let heading of partDetailsHeader">
                                {{heading}}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of partDetailsData">
                            <td>{{item.RepOEMpart}}</td>
                            <td class="replacement-desc text-left">
                                <div>Part - {{item.part}}</div>
                                <div>Material - {{item.material}}</div>
                            </td>
                            <td>{{item.vendor}}</td>
                            <td>{{getStock(item)}}</td>
                            <td>{{item.price | currency}}</td>
                            <td>
                                <button class="btn btn-warning orderNw" (click)="navigationEvent('orderNow',item)"
                                    [disabled]="item.stock == 'Not Available'">
                                    Order Now
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #orderRepTemplate>
    <div class="modal-header h4">
        <div class="row">
            <div class="col-md-4">
        <a (click)="goback('orderNow')" class="goBack">
            <i class="fa fa-backward" aria-hidden="true"></i>Go Back
        </a>
    </div>
    <div class="col-md-8">
        Order Replacement Part
    </div>
    </div>
    </div>
    <div class="modal-body text-center border-left">
        <div class="modalPopupCard modalCancel">
            <div class="modalPopupCardBody card-body srvceTktModal">
                <div class="row">
                <div class='col-xs-12 col-md-4 addressDiv'>
                    <div>Ship From :
                        <div style="word-break: break-all;">{{vendorFrom}}</div>
                    </div>
                    <hr>
                    <div>Ship To :
                        <div style="word-break: break-all;">{{shipToAdrs}}</div>
                    </div>
                </div>
                <div class='col-xs-12  col-md-4 shipMethdDiv'>
                    <div>Quantity :
                        <select (change)="onQuantityChange($event)">
                            <option *ngFor="let item of itemsLength">{{item}}</option>
                        </select>
                    </div>
                    <hr>
                    <div>Shipment Method:
                        <div>
                            <input type="radio" name="shipment" value='stan' [(ngModel)]="defaultshipment"
                                (change)="shipmentMethodChnge($event)"> Standard<br>
                            <input type="radio" name="shipment" value='ex' (change)="shipmentMethodChnge($event)">
                            Express<br>
                            <input type="radio" name="shipment" value='pr' (change)="shipmentMethodChnge($event)">
                            Priority<br>
                            <input type="radio" name="shipment" value='ep' (change)="shipmentMethodChnge($event)">
                            Express Priority
                        </div>
                    </div>
                </div>
                <div class='col-xs-12  col-md-4 priceDiv'>
                    <p>Unit Price : {{unitprice | currency}}</p>
                    <p>Total Price : {{totalprice | currency}}</p>
                    <p>Tax : {{tax | currency}}</p>
                    <p>Shipment Cost : {{shipmentCost | currency}}</p>
                    <p>Net Price : {{netprice| currency}}</p>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row float-right">
            <button type="button" class="btn btn-warning" (click)="navigationEvent('submitOrder')"> Confirm
                Order</button>
        </div>
    </div>
</ng-template>

<ng-template #orderSucesTemplate>
    <div class="modal-header h4">
        {{popupHeading}}
    </div>

    <div *ngIf="loadingSpin" class="text-center">
        <img src="assets/images/spinner.gif" alt="Inprogress" class="processing-img" />
        <span style="font-size:16px;">Please wait, Processing...</span>
    </div>

    <div class="modal-body text-center border-left" *ngIf="!loadingSpin">
        <div class="modalPopupCard modalCancel">
            <div class="modalPopupCardBody card-body srvceTktModal" style="font-size: 16px;color:green">
                {{succesMsg}}
            </div>
            <div class="modal-footer">
                <div class="row float-right">
                    <button type="button" class="btn btn-warning" (click)="navigationEvent('schedule')"
                        *ngIf="popupHeading == 'Order Confirmation'"> Schedule Service</button>
                    <button type="button" class="btn btn-info" (click)="close()"
                        *ngIf="popupHeading == 'Schedule Service Confirmation'"> Close</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #scheduleSerTemplate>
    <form [formGroup]="scheduleServiceForm">
    <div class="modal-header h4">
        Schedule Service
    </div>
    <div class="modal-body border-left">
        <div class="modalPopupCard modalCancel">
            <div class="modalPopupCardBody card-body" style="margin-left:50px;">
                <div class="row">
                    <div class="col-md-3 scheduleSerDiv">
                        <div class="row" style="margin:0 0 5px 0;">
                            <div class="col-md-9 p-0"><span class="servLables">Service Technician</span></div>
                            <div class="col-md-3 p-0"><span class="servLables">Select</span></div>
                        </div>
                        <div class="row m-0" *ngFor="let tech of serviceTechList">
                            <div class="col-md-10 p-0 labelHeadng">{{tech?.name}}</div>
                            <div class="col-md-2 p-0">
                                <input formControlName="technician" type="radio" [value]="tech?.name" name="technician"
                                    (ngModelChange)="onChangeSerTech($event)">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 scheduleSerDiv" style="left:2px;">
                        <div class="row" style="margin:0 0 5px 0;">
                            <div class="col-md-9 p-0"><span class="servLables">Location</span></div>
                            <div class="col-md-3 p-0"><span class="servLables">Select</span></div>
                        </div>
                        <div class="row m-0" *ngFor="let loc of serTechLocList">
                            <div class="col-md-10 p-0 labelHeadng">{{loc}}</div>
                            <div class="col-md-2 p-0 text">
                                <input type="radio" [value]="loc" name="location"
                                formControlName="location" (ngModelChange)="onChnageLoc($event)">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 scheduleSerDiv" style="left:4px;">
                        <span class="servLables">Service Date</span>
                        <mat-form-field class="example-full-width" style="display: block;">
                            <mat-label>Choose a date</mat-label>
                            <input formControlName="serviceDate" matInput [matDatepicker]="picker" [min]="minDate"
                                (dateChange)="dateChnageEvent('change', $event)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 scheduleSerDiv" style="left:6px;">
                        <span class="servLables">Available Time</span>
                        <div *ngFor="let slot of slotsAvl">
                            <input formControlName="serviceTime" type="radio" name="serviceTime" [value]="slot" (click)="onchangeTime($event)">
                            &nbsp;<span class="slotsAvl">{{slot}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row float-right">
            <button type="button" class="btn btn-warning" (click)="navigationEvent('scheduleSer')"
                [disabled]='scheduleDisFlg'>Submit Schedule Service</button>
        </div>
    </div>
    </form>
</ng-template>