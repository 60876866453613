import { Component, OnInit } from '@angular/core';
import * as d3 from "d3v4";
import { Observable } from 'rxjs';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-plant-twin-dialog',
  templateUrl: './plant-twin-dialog.component.html',
  styleUrls: ['./plant-twin-dialog.component.css']
})
export class PlantTwinDialogComponent implements OnInit {

  //   chartInputData = [
  //   { "$dtId": "John", "Parent": "", "spouse": "Isabella" },
  //   { "$dtId": "Aaron", "Parent": "Kevin" },
  //   { "$dtId": "Kevin", "Parent": "John", "spouse": "Emma" },
  //   { "$dtId": "Hannah", "Parent": "Ann", "spouse": "Williams" },
  //   { "$dtId": "Rose", "Parent": "Sarah" },
  //   { "$dtId": "Ann", "Parent": "John", "spouse": "George" },
  //   { "$dtId": "Sarah", "Parent": "Kevin", "spouse": "James" },
  //   { "$dtId": "Mark", "Parent": "Ann" },
  //   { "$dtId": "Angel", "Parent": "Sarah" },
  //   { "$dtId": "Tom", "Parent": "Hannah" }
  // ];

  tenantId = environment.msalConfig.tenantId;
  adtExplorerUrl = "https://explorer.digitaltwins.azure.net/?tid="+this.tenantId+"&eid="+localStorage.getItem('azureDigitalTwinUrl');
  loadingSpin = true;
  //Sandbox API URL
  apiUrl: string = environment.coreApiBaseUrl+environment.plantTwinHierachyEndPoint;

  //Sandbox ADT URL
  adtUrl = "https://"+localStorage.getItem('azureDigitalTwinUrl')+"/query?api-version=2020-10-31";

  chartInputData: any;
  private _sanitizer: DomSanitizer;

  constructor(private http: HttpClient) { 
    this.loadingSpin = true;
    console.log(this.adtExplorerUrl);
  }

  getAdtExplorerUrl(){
    // return "https://explorer.digitaltwins.azure.net/?tid="+this.tenantId+"&eid="+environment.azureDigitalTwinUrl;
    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://explorer.digitaltwins.azure.net/?tid=${this.tenantId}&eid=${localStorage.getItem('azureDigitalTwinUrl')}`);
  }
  getOperationData(): Observable<any> {
    
    let idToken = localStorage.getItem('msal.idtoken');
    let adtToken = localStorage.getItem('adtToken');
    let header_node = {
      Accept: 'application/json',
      rejectUnauthorized: 'false',
      'Authorization':'Bearer '+idToken
    }
      const body= {
        "fromDate":"",
        "toDate":"",
        "adtUrl":this.adtUrl,
        "accessToken":adtToken,
        "adtQuery":"SELECT * FROM DIGITALTWINS"
      };
      //console.log(body)
      return this.http.post(this.apiUrl, body,{'headers':header_node})
    }

  ngOnInit(): void {
    // this.getOperationData()
    // .subscribe(data =>{
    //   console.log(data.value);
    //   this.chartInputData = data.value;
    //   this.loadingSpin = false;
    //   this.renderChart();
    // });
    
  }
  renderChart() {
    var treeData = d3.stratify()
      .id(function (d) { return d.$dtId; })
      .parentId(function (d) { return d.Parent; })(this.chartInputData);

    console.log(treeData);

    // Set the dimensions and margins of the diagram
    var margin = { top: 50, right: 90, bottom: 30, left: 90 },
      width = 1000 - margin.left - margin.right,
      height = 1000 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    var svg = d3.select("#hiearchy").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate("
        + margin.left + "," + margin.top + ")");

    var i = 0,
      duration = 750,
      root;

    // declares a tree layout and assigns the size
    var treemap = d3.tree().size([height, width]);

    // Assigns parent, children, height, depth
    root = d3.hierarchy(treeData, function (d) { return d.children; });
    root.y0 = height / 2;
    root.x0 = 0;

    // Collapse after the second level
    root.children.forEach(collapse);

    update(root);

    // Collapse the node and all it's children
    function collapse(d) {
      if (d.children) {
        d._children = d.children
        d._children.forEach(collapse)
        d.children = null
      }
    }

    function update(source) {

      // Assigns the x and y position for the nodes
      var treeData = treemap(root);

      // Compute the new tree layout.
      var nodes = treeData.descendants(),
        links = treeData.descendants().slice(1);

      // Normalize for fixed-depth.
      nodes.forEach(function (d) { d.y = d.depth * 180 });

      // ****************** Nodes section ***************************

      // Update the nodes...
      var node = svg.selectAll('g.node')
        .data(nodes, function (d) { return d.id || (d.id = ++i); });

      // Enter any new modes at the parent's previous position.
      var nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr("transform", function (d) {
          return "translate(" + source.y0 + "," + source.x0 + ")";
        })
        .on('click', click);

      // Add Circle for the nodes
      nodeEnter.append('circle')
        .attr('class', 'node')
        .attr('r', 1e-6)
        .style("fill", function (d) {
          return d._children ? "lightsteelblue" : "#fff";
        });

      // Add labels for the nodes
      nodeEnter.append('text')
        .attr("dy", ".35em")
        .attr("x", function (d) {
          return -20;
        })
        /*.attr("text-anchor", function(d) {
            return d.children || d._children ? "end" : "start";
        })*/
        .text(function (d) { return d.data.id; });

      // UPDATE
      var nodeUpdate = nodeEnter.merge(node);

      // Transition to the proper position for the node
      nodeUpdate.transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + d.x + "," + d.y + ")";
        });

      // Update the node attributes and style
      nodeUpdate.select('circle.node')
        .attr('r', 35)
        .style("fill", function (d) {
          return d._children ? "lightsteelblue" : "#fff";
        })
        .attr('cursor', 'pointer');


      // Remove any exiting nodes
      var nodeExit = node.exit().transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + source.x + "," + source.y + ")";
        })
        .remove();

      // On exit reduce the node circles size to 0
      nodeExit.select('circle')
        .attr('r', 1e-6);

      // On exit reduce the opacity of text labels
      nodeExit.select('text')
        .style('fill-opacity', 1e-6);

      // ****************** links section ***************************

      // Update the links...
      var link = svg.selectAll('path.link')
        .data(links, function (d) { return d.id; });

      // Enter any new links at the parent's previous position.
      var linkEnter = link.enter().insert('path', "g")
        .attr("class", "link")
        .attr("stroke", "yellow")
        .attr('d', function (d) {
          var o = { x: source.y0, y: source.x0 }
          return diagonal(o, o)
        });

      // UPDATE
      var linkUpdate = linkEnter.merge(link);

      // Transition back to the parent element position
      linkUpdate.transition()
        .duration(duration)
        .attr('d', function (d) { return diagonal(d, d.parent) });

      // Remove any exiting links
      var linkExit = link.exit().transition()
        .duration(duration)
        .attr('d', function (d) {
          var o = { x: source.x, y: source.y }
          return diagonal(o, o)
        })
        .remove();

      // Store the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.y;
        d.y0 = d.x;
      });

      // Creates a curved (diagonal) path from parent to the child nodes
      function diagonal(s, d) {

        let path = `M ${s.x} ${s.y}
            C ${(s.x + d.x) / 2} ${s.y},
              ${(s.x + d.x) / 2} ${d.y},
              ${d.x} ${d.y}`

        return path
      }

      // Toggle children on click.
      function click(d) {
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
        update(d);
      }
    }
  }

}
