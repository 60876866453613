export var single = [
    {
      "name": "ProActive",
      "value": 30
    },
    {
      "name": "Periodic",
      "value": 30
    },
    {
      "name": "Breeakdown",
      "value": 40
    }
  ];