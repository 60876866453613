export var single = [
  {
    "name": "Closed",
    "value": 40
  },
  {
    "name": "New",
    "value": 5
  },
  {
    "name": "Inprogress",
    "value": 5
  }
];
