<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="csv-sim">
                <div class="title-info">
                    Configuration
                </div>
                <div class="ev-table">
                    <div class="fit-table-data">
                        <app-rotation-config-list></app-rotation-config-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>