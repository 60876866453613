<div class="body-wrap">
    <header class="site-header">
      <div class="container">
        <div class="site-header-inner">
          <div class="brand header-brand">
            <h1 class="m-0">
              <a href="#">
                <img class="header-logo-image" src="assets/images/EY_Logo_Beam_RGB.png" width="50px" alt="Logo">
              </a>
            </h1>
          </div>
        </div>
      </div>
    </header>
  
    <main>
      <section class="hero">
        <div class="container">
          <div class="hero-inner">
            <div class="hero-copy">
              <!-- <h1 class="hero-title mt-0" style="font-size: 38px;margin-bottom: 25px;font-weight: 700;">Polar Delight</h1> -->
              <img class="hero-title mt-0" src="assets/images/rheem.png" alt="Polar Delight Logo"
                style="margin-bottom: 25px;height: 202px;" />
              <!-- <h4 class="hero-paragraph mt-0" style="font-weight: unset;font-size: 24px;">Asset Digital Twin</h4> -->
              <h4 class="hero-paragraph mt-0" style="font-weight: unset;font-size: 24px;">Digital Enterprise Realized</h4>
              <!-- <p class="hero-paragraph" style="color: lightslategrey;font-size: 18px;margin-bottom:27px">Command & Control
                Center, Twin based Predictive Analytics</p> -->
                <p class="hero-paragraph" style="color: lightslategrey;font-size: 18px;margin-bottom:27px">Asset Digital Twin & Predictive Analytics</p>
              <div class="hero-cta"><a class="button button-primary" (click)="login()">Service</a></div>
            </div>
            <div class="hero-figure anime-element">
              <svg class="placeholder" width="528" height="396" viewBox="0 0 528 396">
                <rect width="528" height="396" style="fill:transparent;" />
              </svg>
              <div class="hero-figure-box hero-figure-box-01" data-rotation="45deg"></div>
              <div class="hero-figure-box hero-figure-box-02" data-rotation="-45deg"></div>
              <div class="hero-figure-box hero-figure-box-03" data-rotation="0deg"></div>
              <div class="hero-figure-box hero-figure-box-04" data-rotation="-135deg"></div>
              <div class="hero-figure-box hero-figure-box-05"></div>
              <div class="hero-figure-box hero-figure-box-06"></div>
              <div class="hero-figure-box hero-figure-box-07"></div>
              <div class="hero-figure-box hero-figure-box-08" data-rotation="-22deg"></div>
              <div class="hero-figure-box hero-figure-box-09" data-rotation="-52deg"></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>