import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
@Injectable({
providedIn: 'root'
})
export class FileUploadService {
headers: any;	
// API urlconstructor(private https: HttpClient) { }
constructor(private authService: MsalService, private https: HttpClient, private auth: MsalService) {
}


  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'multipart/form-data;boundary=----WebKitFormBoundarys83Euzhs8ZNT4tQy',
      'Authorization': 'Bearer ' + idToken,


    }
    this.headers = headers;
    const data: FormData = new FormData();

    data.append('file', file);

    const newRequest = new HttpRequest('POST', 'https://localhost:8443/savefile', data, {
      reportProgress: true,
   //   headers: this.headers,
      responseType: 'text'
    });

    return this.https.request(newRequest);
  }

  getMachineIssues(file: File): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'multipart/form-data',
      'Authorization':'Bearer '+idToken

    }
    const data: FormData = new FormData();

    data.append('file', file);
    return this.https.post('https://localhost:8443/securedApi/savefile', data, {  reportProgress: true,
    headers: headers,
    responseType: 'text' })
  }
}
