<mat-sidenav-container class="menu-container" autosize>
  <mat-sidenav #sidenav class="menu-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
    (mouseleave)="mouseleave()">
    <mat-nav-list>
      <mat-list-item (click)="getMenuDetails(1)" class="parent" [ngClass]="{'selected-menu' : selectedMenu1}">
        <img src="assets/images/coffee_maker.png" alt="plantView" class="home-icon" />
        <span class="full-width" *ngIf="isExpanded || isShowing">Polar Delight</span>
      </mat-list-item>
      <mat-list-item (click)="getMenuDetails(2)" class="parent" [ngClass]="{'selected-menu' : selectedMenu2}">
        <img src="assets/images/precision_manufacturing.png" alt="plantView" class="home-icon" />
        <span class="full-width" *ngIf="isExpanded || isShowing">CNC</span>
      </mat-list-item>
      <mat-list-item (click)="getMenuDetails(3)" class="parent" [ngClass]="{'selected-menu' : selectedMenu3}" *ngIf='("opcSim" | role:userRole) && userRole != "TV User"'>
        <img src="assets/images/realTime.png" alt="plantView" class="home-icon" />
        <span class="full-width" *ngIf="isExpanded || isShowing">Device Simulator</span>
      </mat-list-item>
      <mat-list-item (click)="getMenuDetails(4)" class="parent" [ngClass]="{'selected-menu' : selectedMenu4}" *ngIf='"opcSim" | role:userRole'>
        <i class="home-icon fa fa-motorcycle mr-1" style="font-size:23px;color:white" alt="plantView" aria-hidden="true"></i>
        <span class="full-width" *ngIf="isExpanded || isShowing">Electric Vehicle</span>
      </mat-list-item>
      <mat-list-item (click)="getMenuDetails(5)" class="parent" [ngClass]="{'selected-menu' : selectedMenu5}" *ngIf='"opcSim" | role:userRole'>
        <i class="fa fa-tint mr-1" aria-hidden="true" style="font-size:23px;color:white" alt="plantView" aria-hidden="true"></i>
        <span class="full-width" *ngIf="isExpanded || isShowing">Smart Water Heater</span>
      </mat-list-item>
    </mat-nav-list>

  </mat-sidenav>

  <div class="menu-sidenav-content">
    <router-outlet></router-outlet>
  </div>

</mat-sidenav-container>
