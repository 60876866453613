<ngx-charts-bar-vertical
  [view]="view"
  [scheme]="colorScheme"
  [results]="results"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [barPadding]="barPadding"
  [legendPosition]="legendPosition"
  [rotateXAxisTicks]="true"
  [trimXAxisTicks]="trimXAxisTicks"
  [maxXAxisTickLength]="8"
  (select)="onSelect($event)">
</ngx-charts-bar-vertical>
