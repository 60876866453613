import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse } from 'msal';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sales-form',
  templateUrl: './sales-form.component.html',
  styleUrls: ['./sales-form.component.css']
})
export class SalesFormComponent implements OnInit {
  enableManualFormEntries: boolean=false;
  enableAutoSumulateTable : boolean = false;
  curr = new Date;
  loadingSpin : boolean = false;
  errorCode: string;
  sapResponse: any;
  apiUrl: string = environment.coreApiBaseUrl+environment.sapCommonServicesEndPoint;
  assetsUrl: string = environment.coreApiBaseUrl + environment.retrieveAssetsListEndPoint
  assetsOPCAssetsUrl: string = environment.coreApiBaseUrl + environment.retriveOPCMOdelListEndPoint
  responseString: string;
  salesTypes: any=['Sales','Replenishment'];
  podCodes: any=['PD-200_V', 'PD-200_C', 'PD-200_S', 'PD-200_CC', 'PD-200_MC', 'PD-200_SC'];
  assetList: any=[];
  opcModelList: OPCModel[]=[];
  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef,private datepipe: DatePipe) {
  }

  ngOnInit(): void {
    this.populateDevicesList();
    this.fetchAllAssets();
  }
  fetchAllAssets() {
    this.fetchAllOPCAssets().subscribe(
      (response) => {

        //this.deviceListResponse = JSON.stringify(response);
       // console.log("Device List Response: " + this.deviceListResponse)
        response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.opcModelList.push(element);
        });
      //  console.log("opcModelList: "+this.opcModelList);
      //   this.opcModelList.forEach(item => console.log("Display Name:"+item.displayname))
      },
      (error) => {
        console.log("There Was A Problem While Fetching OPC Assets")
      });
  }
  fetchAllOPCAssets(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.assetsOPCAssetsUrl, "", { 'headers': headers })
  }
  populateDevicesList() {
    this.getDeviceListResponse().subscribe(
      (response) => {

        //this.deviceListResponse = JSON.stringify(response);
       // console.log("Device List Response: " + this.deviceListResponse)
        response.forEach(element => {
          this.assetList.push(element.assetId);
        });
      },
      (error) => {
        console.log("There Was A Problem While Fetching Assets")
      });
  }
  getDeviceListResponse(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.assetsUrl, "", { 'headers': headers })
  } form = new FormGroup({
    saleOrreplenishment: new FormControl('', [Validators.required]),
    sim: new FormControl('', [Validators.required]),
    assetId: new FormControl('', [Validators.required]),
    location: new FormControl('', [Validators.required, Validators.minLength(3)]),
    podBarCode: new FormControl('', [Validators.required, Validators.minLength(3)]),
    podNumber: new FormControl('', [Validators.required, Validators.minLength(1)]),
    priorityLevel: new FormControl('', [Validators.required, Validators.minLength(1)]),
    timestamp: new FormControl('', [Validators.required, Validators.minLength(3)])
  });
  get f(){
    return this.form.controls;
}

submit(){
  this.loadingSpin=true;
  this.responseString= '';
  if(this.form.status === 'VALID'){
    console.log(this.form.value);
  }
  this.getSapResponse().subscribe(
    (response) => {
      if(response){this.loadingSpin=false;hideloader();}
      if(response.TicketId != undefined){
        this.responseString = "Sales/Replenishment Ticket ID: "+response.TicketId;
       } else if(response.ErrorResponse != undefined){
        this.responseString = "Error: "+response.ErrorResponse[0].errorMessage;
       }
    },
    (error) => {
      if(error){this.loadingSpin=false;hideloader();}
      console.log("There Was A Problem Registering Sales With SAP")
      this.responseString= "There Was A Problem Registering Service With SAP. Contant Support @EY IoT";
    });
    function hideloader() {
        document.getElementById('loading').style.display = 'none';
    }
}

getSapResponse(): Observable<any> {
  let idToken = localStorage.getItem('msal.idtoken');
  const headers = {
    'content-type': 'application/json',
    'Authorization':'Bearer '+idToken
}
 if(this.form.value.saleOrreplenishment == "Sales"){
  const body= {
    "errorCode" : "",
    "errorDesc" : "",
    "podbarCode" : this.form.value.podBarCode,
    "podsalesNum" : this.form.value.podNumber,
    "priority" : this.form.value.priorityLevel,
    "location" : this.form.value.location,
    "type" : this.form.value.saleOrreplenishment,
    "customfield1" : "",
    "customfield2" : "",
    "customfield3" : "",
    "customfield4" : "",
    "customfield5" : "",
    "customfield6" : "",
    "machineId" : this.form.value.assetId,
    "timestamp" : this.form.value.timestamp
  };
  console.log(body)
  return this.http.post(this.apiUrl, body,{'headers':headers})
 }else if(this.form.value.saleOrreplenishment == "Replenishment"){
  const body= {
    "errorCode" : "",
    "errorDesc" : "",
    "podbarCode" : this.form.value.podBarCode,   
    "podreplNum" : this.form.value.podNumber,
    "priority" : this.form.value.priorityLevel,
    "location" : this.form.value.location,
    "type" : this.form.value.saleOrreplenishment,
    "customfield1" : "",
    "customfield2" : "",
    "customfield3" : "",
    "customfield4" : "",
    "customfield5" : "",
    "customfield6" : "",
    "machineId" : this.form.value.assetId,
    "timestamp" : this.form.value.timestamp
  };
  console.log(body)
  return this.http.post(this.apiUrl, body,{'headers':headers})
 }
  
 
}

setValue(){
  this.form.setValue({type: 'Sales', sim:'Manual',assetId:'PD01',location:'New York', podBarCode:'PD_200_V', podNumber: '1', priorityLevel: '4',timestamp:new Date});
}

resetValue(){
  this.form.reset({type: '',sim:'',assetId:'',location:'', podBarCode:'', podNumber:'', priorityLevel:'',timestamp:''});
}
selectOption(id: number) {
  //getted from event
  //console.log("Selected ID:   "+id);
  //getted from binding
 if(id==1){
  this.enableManualFormEntries = true;
  this.enableAutoSumulateTable = false
 }else{
  this.enableManualFormEntries =false;
  this.enableAutoSumulateTable = true;
 }
 //console.log("enableManualFormEntries: "+this.enableManualFormEntries)
}
populateAssetDetails(asset: string){
  console.log("Selected Asset: "+asset)
  this.opcModelList.forEach(item => {
    if(item.displayname == asset && item.folderid == 'Location'){
      console.log("item.tab: "+item.tab);
      if(item.tab.includes(",")){
        const myArr = item.tab.split(",")[0];
        this.form.patchValue({
          location: myArr,
          
          });
      }else{
        this.form.patchValue({
          location: item.tab,
          
          });
      }
    
    }
    // else if(item.displayname == asset && item.folderid == 'PODCode'){
    //   this.form.patchValue({
    //     podBarCode: item.tab,
    //     });
    //   }

  })

  let latestDate = this.datepipe.transform(new Date, 'yyyy-MM-dd h:mm:ss');
  this.form.patchValue({
    timestamp: latestDate ,
    });
  }
  populatePriority(serviceType: string){
    console.log("Selected serviceType: "+serviceType);
    if(serviceType == "Sales"){
      this.form.patchValue({
        priorityLevel: 4,
        });
    }else if(serviceType == "Replenishment"){
      this.form.patchValue({
        priorityLevel: 3,
        });
    }
  } 
}

export interface OPCModel {
  displayname: string;
  folderid: string;
  tab: string;
}
