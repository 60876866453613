import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env';
import { of, Observable } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { EvOperationData, Asset } from './ev-operation.model';
import { share } from 'rxjs/operators';

@Injectable()
export class EvOperationService {
    //Sandbox ADT URL
    digitalTwinUrl = this.getLocalstorageItem('azureDigitalTwinUrl');
    adtUrl: string = "https://" + this.digitalTwinUrl + "/query?api-version=2020-10-31";
    azureDigitalTwinScope: string[] = ["https://digitaltwins.azure.net/Read.Write"];
    adtExplorerUrl: string = "https://explorer.digitaltwins.azure.net/?tid=" + environment.msalConfig.tenantId + "&eid=" + this.digitalTwinUrl;
    autoRefreshPeriod: number = environment.evOperationAutoRefreshPeriodInSeconds * 1000;

    constructor(private auth: MsalService, private httpService: HttpService) { }

    getAssetsList(): Observable<Asset[]> {
        const requiredItem: number = 6;
        const assetData = [...Array(requiredItem).keys()].map(item => {
            const next = item + 1;
            return {
                "assetId": `EV_${next <= 9 ? `0${next}` : next}`,
                "id": next,
                "serverenableflag": true
            }
        });
        return of(assetData);
    }

    getOperationData(assetId: string): Observable<EvOperationData> {
        const accessToken = this.getLocalstorageItem('adtToken');
        const headers = {
            rejectUnauthorized: 'false'
        }
        const body = {
            "fromDate": "",
            "toDate": "",
            "adtUrl": this.adtUrl,
            accessToken,
            "adtQuery": "SELECT * FROM DIGITALTWINS T WHERE T.$dtId = '" + assetId + "'"
        };
        return this.httpService.post(environment.evOperationsEndPoint, body, { headers }).pipe(share())
    }

    getLocalstorageItem(key) {
        return localStorage.getItem(key);
    }

    updateLocalStorageItem(key, data) {
        localStorage.setItem(key, data);
    }

    getToken() {
        return this.auth.acquireTokenSilent({ scopes: this.azureDigitalTwinScope })
    }

    get resetNeedleValueData() {
        return [
            'batteryPowerInWatts', 
            'batteryTemperature', 
            'bikeMotorTemperature', 
            'bikeRpm',
            'bikeSpeedInMPH', 
            'soCPercentage'
        ];
    }

    // Static default data
    get defaultValue(): EvOperationData {
        return {
            "machineStatus": 'Online',
            "assetLocation": "Cleveland, OH, USA, 44101",
            "bikeOdometerInMiles": {
                "bikeOdometerInMiles": "1150",
                "min": "0",
                "needleValue": 60,
                "max": "2000"
            },
            "bikeSpeedInMPH": {
                "bikeSpeedInMPH": "5",
                "min": "0",
                "needleValue": 40,
                "max": "100"
            },
            "batteryPowerInWatts": {
                "batteryPowerInWatts": "437",
                "min": "-200",
                "needleValue": 60,
                "max": "15000",
                "status": "OK"
            },
            "TimeInSeconds": "1.99",
            "SpeedThrottlePercentage": "24",
            "assetId": "EV_01",
            "batteryTemperature": {
                "min": "0",
                "needleValue": 10,
                "max": "60",
                "batteryTemperature": "25",
                "status": "OK"
            },
            "bikeMotorTemperature": {
                "min": "0",
                "needleValue": 60,
                "max": "100",
                "bikeMotorTemperature": "49"
            },
            "bikeRpm": {
                "min": "0",
                "needleValue": 60,
                "max": "6000",
                "bikeRpm": "720"
            },
            "soCPercentage": {
                "soCPercentage": "62",
                "status": "OK"
            },
            "RideMode": "Economy",
            "rideModeThrottlePercentage": {
                "rideModeThrottlePercentage": "1186",
                "status": 'OK'
            },
            "estimateRange": {
                "estimateRange": 80,
                "status": 'OK'
            }
        };
    }

}