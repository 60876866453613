<div class="container1" [ngStyle]="{'min-width.px':innerWidth+90,'overflow-y':'auto','overflow-x':'hidden'}" style="height:90%">
    <div class="row text-content">
    <!-- <p class="h4 text-center p-2">OPC Simulator</p>
    <p style="font-size: 8pt;text-align: end;margin-right: 10%;">{{from | date | date:'mediumDate'}} to {{to | date | date:'mediumDate'}}</p> -->
    <div class="col-sm-8 text-right text-style1">Device Simulator  </div>
     <div class="col-sm-4 text-right text-style2">YTD</div>
  </div>
    <mat-tab-group mat-align-tabs="left" dynamicHeight="true" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Device Configuration">
        <ng-template matTabContent>
            <app-device-config></app-device-config>
        </ng-template>
      </mat-tab>
      <mat-tab label="Sales">
        <ng-template matTabContent>
         <app-sales-opc-simulator></app-sales-opc-simulator>
        </ng-template>
      </mat-tab>
      <mat-tab label="Services">
        <ng-template matTabContent>
         <app-service-opc-simulator></app-service-opc-simulator>
        </ng-template>
      </mat-tab>
      <mat-tab label="CSV Simulator">
        <ng-template matTabContent>
          <app-csv-simulator></app-csv-simulator>
          <!-- <app-file-upload></app-file-upload>  -->
        </ng-template>
      </mat-tab>
      <mat-tab label="TV Config">
        <ng-template matTabContent>
          <app-rotation-config></app-rotation-config>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>


