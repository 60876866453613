import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse } from 'msal';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { MatDialog } from '@angular/material/dialog';
import { PlantTwinDialogComponent } from '../../shared/plant-twin-dialog/plant-twin-dialog.component';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-pd-operations',
  templateUrl: './pd-operations.component.html',
  styleUrls: ['./pd-operations.component.css']
})
export class PdOperationsComponent implements OnInit,OnDestroy {

  @Input() assetId : any;
  interval: any;
  autoRefreshPeriod = environment.operationAutoRefreshPeriodInSeconds * 1000;
  loading = false;
  podId="PD_01";

  adtExplorerUrl = "https://explorer.digitaltwins.azure.net/?tid="+environment.msalConfig.tenantId+"&eid="+localStorage.getItem('azureDigitalTwinUrl');

  //Sandbox API URL
  apiUrl: string = environment.coreApiBaseUrl+environment.pdOperationsEndPoint;

  //Sandbox ADT URL
  adtUrl = "https://"+localStorage.getItem('azureDigitalTwinUrl')+"/query?api-version=2020-10-31";

  adtToken: string;
  //azureDigitalTwinScope = ["8f513527-1e6b-4d54-8456-22abbb0ea1d9"];
  azureDigitalTwinScope = ["https://digitaltwins.azure.net/Read.Write"];
  pdOperationData: any;
  fetchAllAssetUrl=environment.coreApiBaseUrl+environment.retrieveAssetsListEndPoint;
  assetIdList:any[]=[];
  capPosition: any[];
  podInterval: any;
  next: number = 0;
  data: any;

  constructor(private http: HttpClient, 
    private auth: MsalService,
    public dialog: MatDialog,
    private sharedService: SharedService) {
  }


  ngOnInit(): void {
  this.setDefaultCapPosition();
  this.setDefaultValues();
  this.loadAssetIdsList();
  this.fetchTokenAndGetPdOperationData();
  this.loadPdOperationDataPeriodically();
  }

  get randomNumberInteval() {
    return this.sharedService.randomIntFromInterval(1,10);
  }

  setDefaultCapPosition() {
    this.capPosition = [{
      name: 'Vanilla',
      value: this.randomNumberInteval
    },{
      name: 'Strawberry ShortCake',
      value: this.randomNumberInteval
    },{
      name: 'Chocolate',
      value: this.randomNumberInteval
    },{
      name: 'Mint & Chocolate',
      value: this.randomNumberInteval
    },{
      name: 'Cookies & Cream',
      value: this.randomNumberInteval
    },{
      name: 'Salty Caramel',
      value: this.randomNumberInteval
    }];
  }

  loadPdOperationDataPeriodically(){
    this.interval = setInterval(() => {
      this.fetchTokenAndGetPdOperationData();
    }, this.autoRefreshPeriod);
  }

  fetchTokenAndGetPdOperationData(assetId?:string){
    this.auth.acquireTokenSilent(
      { scopes: this.azureDigitalTwinScope }
      )
      .then((response: AuthResponse) => {
        this.adtToken = response.accessToken;
        localStorage.setItem('adtToken', this.adtToken);
        //console.log(this.adtToken);
      }).catch(err => {
        console.log(err);
      }).then(_ => {
        this.getOperationData(assetId)
        .subscribe(data =>{
          this.pdOperationData = data;
          this.loading = false;
        });
      });
  }

  /**
   * Change value of every flavour after 2 min
   */
  generatePodData() {
    this.podInterval = setInterval(() => {
      if(this.pdOperationData.machineStatus === 'Online') {
        if(this.next >= this.capPosition.length) this.next = 0;
        const current = this.next;
        const value = this.capPosition[current].value
        this.capPosition[current].value = value <= 1 ? 10 : value - 1;
        this.next += 1;
      }
    }, 2 * 60 * 1000);
  }

  getOperationData(assetId?:string): Observable<any> {
  //   const headers = {
  //     'content-type': 'application/json',
  //     // 'Accept': 'application/json',
  //     // 'rejectUnauthorized': 'false',
  //     // 'requestCert': 'false',
  //     // 'insecure': 'true',
  //   'Authorization': `Bearer ${this.adtToken}`,
  //   // 'Access-Control-Allow-Origin' : '*'
  // }
  let idToken = localStorage.getItem('msal.idtoken');
  let header_node = {
    Accept: 'application/json',
    rejectUnauthorized: 'false',
    'Authorization':'Bearer '+idToken
  }

  if(this.assetId===undefined){
    this.assetId = this.podId;
  }else if(assetId){
    this.assetId=assetId;
  }
    const body= {
      "fromDate":"",
      "toDate":"",
      "adtUrl":this.adtUrl,
      "accessToken":this.adtToken,
      "adtQuery":"SELECT * FROM DIGITALTWINS T WHERE T.$dtId = '"+this.assetId+"'"
    };
    //console.log(body)
    return this.http.post(this.apiUrl, body,{'headers':header_node})
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    clearInterval(this.podInterval)
  }

  openDialog(){
    window.open(this.adtExplorerUrl, "_blank");
    // clearInterval(this.interval);
    // let dialogRef = this.dialog.open(PlantTwinDialogComponent , {
    //   width : "100%",
    //   height : "100%"
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.loadPdOperationDataPeriodically();
    // });
  }

  setDefaultValues() {
    this.pdOperationData = {
      "machineStatus": "Online",
      "capPositionData": {
        "capPosition": "Down",
        "status": "FAULT"
      },
      "ramPositionData": {
        "min": "55",
        "max": "95",
        "ramPosition": "75",
        "status": "OK"
      },
      "cupDetect": "No",
      "assetHealthData": {
        "needleValue": 95,
        "assetHealth": "Very Good"
      },
      "podBinDoor": "Closed",
      "dispensePressureData": {
        "min": "5",
        "needleValue": 46,
        "max": "40",
        "dispensePressure": "46"
      },
      "freezerTemperatureData": {
        "freezerTemperature": "5",
        "min": "-50",
        "needleValue": 5,
        "max": "5"
      },
      "maxRamLoadData": {
        "min": "10",
        "ramLoad": "50",
        "max": "25",
        "status": "FAULT"
      },
      "cycleTimeData": {
        "cycleTime": "13",
        "min": "5",
        "needleValue": 13,
        "max": "60"
      },
      "customerDoor": "Open",
      "assetLocation": "Cleveland",
      "assetId": 'PD_01',
      "podCode": "85025",
      "cycleStatusData": {
        "cycleStatus": "Complete",
        "status": "OK"
      }
    }
    this.generatePodData(); // Set interval
  }

  loadAssetIdsList(){
   this.getAssetsList().subscribe(res=>{
    this.assetIdList=res;
   })
  }

  getAssetsList(): Observable<any>{
  let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.fetchAllAssetUrl, "", { 'headers': headers })
  }

  

  onChnageOfAsset(e){
    const {value,text} = e.target.options[e.target.selectedIndex]
    this.fetchTokenAndGetPdOperationData(text);
  }


}
