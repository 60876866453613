import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env";
import { Observable } from "rxjs";
import { HttpService } from "../../services/http.service";
import { TabModel } from "./cnc-remote-op.model";

@Injectable()
export class CncService {

    constructor(private http: HttpService) { }

    getAllServiceTickets(offset, limit,status:string): Observable<any> {
        let params = new HttpParams();
        params = params.set('page', offset);
        params = params.set('size', limit);
        params= params.set('status',status);
        params = params.set('sort', 'r_mod_time,desc');
        return this.http.post(environment.cncServiceTicketsUrl + '?' + params.toString());
        // return of(SERVICE_TICKET_DATA).pipe(delay(1 * 1000));
    }

    getOverallTicketStatus(): Observable<any> {
        return this.http.post(environment.cncOperationSummary);
    }

    tabDataByDesc(desc) {
        const selectedTab = TABS_DATA.find(({description}) => description === desc);
        return !selectedTab ? TABS_DATA[0].data : selectedTab.data;
    }
}

const TABS_DATA: TabModel[] = [
    {
        description: 'Temperature Out Of Range',
        data: [
            {
                label: 'Description',
                title: 'Temperature Out Of Range',
                description: {
                    list: {
                        style: false,
                        type: 'number'
                    },
                    data: [{
                        label: 'The temperature has hit 77°F. The optimum temperature is 10°F or lower.'
                    }]
                }
            },
            {
                label: 'Root Causes',
                title: 'Root Causes for variation in Coolant Capacity Degradation',
                description: {
                    list: {
                        style: true,
                        type: 'number'
                    },
                    data: [{
                        label: 'Electrical cabinet poorly ventilated.'
                    },
                    {
                        label: 'Axis board with some defective component.'
                    }]
                }
            },
            {
                label: 'Prescriptive',
                title: 'Technician Checks on Site Visits:',
                description: {
                    list: {
                        style: true,
                        type: 'number'
                    },
                    data: [{
                        label: 'Turn the CNC and wait until it cools off.'
                    },
                    {
                        label: 'A component of the board may be defective.'
                    },
                    {
                        label: 'Replace the board.'
                    }
                    ]
                }
            }
        ]
    },
    {
        description: 'Coolant Capacity Degradation',
        data: [
            {
                label: 'Description',
                title: 'Coolant Capacity Degradation',
                description: {
                    list: {
                        style: false,
                        type: 'number'
                    },
                    data: [{
                        label: 'The temperature has hit 25°C. The optimum temperature is 10°C or colder.'
                    }]
                }
            },
            {
                label: 'Possible Causes',
                title: 'Possible Causes for variation in Coolant Capacity Degradation',
                description: {
                    list: {
                        style: true,
                        type: 'number'
                    },
                    data: [{
                        label: 'Motor bearing worn out.'
                    },
                    {
                        label: 'ESC damaged.'
                    },
                    {
                        label: 'Issue in charging circuit.'
                    }]
                }
            },
            {
                label: 'Remedial Steps',
                title: 'Technician Checks on Site Visits:',
                description: {
                    list: {
                        style: true,
                        type: 'number'
                    },
                    data: [{
                        label: 'Verify if battery terminal is connected properly.'
                    },
                    {
                        label: 'Verify power supply.'
                    },
                    {
                        label: 'Replace ESC.'
                    }]
                }
            }
        ]
    },
    {
        description: 'Motor controller fails',
        data: [
            {
                label: 'Description',
                title: 'Motor controller failure',
                description: {
                    list: {
                        style: false,
                        type: 'number'
                    },
                    data: [{
                        label: 'Motor temprature has hit 80°C, that can be damaged by getting contaminated by dirt, moisture, oil or grease.'
                    }]
                }
            },
            {
                label: 'Possible Causes',
                title: 'Possible Causes for variation in Battery Temperature',
                description: {
                    list: {
                        style: true,
                        type: 'number'
                    },
                    data: [{
                        label: 'Flashover to Outer Bearings'
                    },
                    {
                        label: 'Loss of Phase Voltage'
                    },
                    {
                        label: 'Overheating'
                    }]
                }
            },
            {
                label: 'Remedial Steps',
                title: 'Technician Checks on Site Visits:',
                description: {
                    list: {
                        style: true,
                        type: 'number'
                    },
                    data: [{
                        label: 'Verify if motor is connected properly.'
                    },
                    {
                        label: 'Verify input Voltage.'
                    },
                    {
                        label: 'Verify motor is not overheating.'
                    }]
                }
            }
        ]
    },
]