<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-group">
        <div id="services">
            <label class="mr-sm-2 sr-only" for="inlineFormCustomSelect">Service Type</label>
            <select class="custom-select" id="inlineFormCustomSelect" formControlName="services"
                    (change)="setAdditionalField($event.target.value)">
                <option selected>Service Type...</option>
                <option *ngFor="let serviceType of serviceTypes">{{serviceType}}</option>
            </select>
            <div *ngIf="f.services.touched && f.services.invalid" class="alert alert-danger">
                <div *ngIf="f.services.errors.required">Service Type is required.</div>
            </div>
        </div>
        <br>
        <div id="sim">
            <label class="mr-sm-2 sr-only" for="inlineFormCustomSelectSim">Type</label>
            <select class="custom-select mr-sm-2" id="inlineFormCustomSelectSim"
                (change)="selectOption($event.target.value)">
                <option selected>SIM Type...</option>
                <option value="1">Manual</option>
                <!-- <option value="2">Auto</option> -->
            </select>
        </div>
        <br>
        <div *ngIf="enableManualService">
            <div id="assetId">
                <label class="mr-sm-2 sr-only" for="inlineFormCustomSelectassetId">Machine Name</label>
                <select class="custom-select mr-sm-2" id="inlineFormCustomSelectassetId" formControlName="assetId"
                (change)=populateLocation($event.target.value)>
                    <option selected>Select Asset...</option>
                    <option *ngFor="let assetId of assetList">{{assetId}}</option>
                </select>
            </div>
            <br>
            <div id="errorCode">
                <label class="mr-sm-2 sr-only" for="inlineFormCustomSelecterrorCode">Error Code</label>
                <select class="custom-select mr-sm-2" id="inlineFormCustomSelecterrorCode" formControlName="errorCode"
                (change)=populateErrorDetails($event.target.value)>
                    <option selected>Select Error Code...</option>
                    <option *ngFor="let errorcode of errorCodes">{{errorcode}}</option>
                </select>
            </div>
            

            <!-- <div class="form-group">
                <label for="errorCode">Error Code</label>
                <input formControlName="errorCode" id="errorCode" type="text" class="form-control" />

                <div *ngIf="f.errorCode.touched && f.errorCode.invalid" class="alert alert-danger">
                    <div *ngIf="f.errorCode.errors.required">Value is required.</div>
                </div>
            </div> -->

            <div class="form-group">
                <label for="errorDesc">Error Description</label>
                <input formControlName="errorDesc" id="errorDesc" type="text" class="form-control" />

                <div *ngIf="f.errorDesc.touched && f.errorDesc.invalid" class="alert alert-danger">
                    <div *ngIf="f.errorDesc.errors.required">Value is required.</div>
                </div>
            </div>

            <div class="form-group">
                <label for="location">Location</label>
                <input formControlName="location" id="location" type="text" class="form-control" value="f.location"/>

                <div *ngIf="f.location.touched && f.location.invalid" class="alert alert-danger">
                    <div *ngIf="f.location.errors.required">Value is required.</div>
                </div> 
            </div>
            <div id="additionalField">
                <label class="mr-sm-2 sr-only" for="inlineFormCustomAdditionalField">Additional Field</label>
                <select class="custom-select mr-sm-2" id="inlineFormCustomAdditionalField" formControlName="additionalField">
                    <option selected>Select Additional Field...</option>
                    <option *ngFor="let additionalField of additionalFields">{{additionalField}}</option>
                </select>
            </div>
        </div>
    </div>

    <button class="btn btn-info" type="submit">Trigger Now</button>&nbsp;
    <!-- <button class="btn btn-info" type="button" (click)="resetValue()">Reset Value</button> -->
</form>
<br>
<div *ngIf="loadingSpin" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
        <span class="sr-only" id="loading"></span>
    </div>
</div>
{{responseString}}
<!-- <div *ngIf="enableAutoService">
    <p>Select Device(s) to Simulate:</p>
   <app-device-list></app-device-list>
</div> -->
