<div style="width: 100%;margin-top: 5px;">
  <div>
    <p style="font-size: 11pt; color: rgb(209, 209, 209);margin-left: 15px;margin-bottom: 3px;text-align: center;">Critical Events</p>
    <button class="btn btn-info" style="margin-bottom: 5px;margin-left: 15px;"
      (click)="exporter.exportTable('txt')">Text</button>&nbsp;
    <button class="btn btn-info" style="margin-bottom: 5px;" (click)="exporter.exportTable('xlsx')">Excel</button>&nbsp;
    <button class="btn btn-info" style="margin-bottom: 5px;" (click)="exporter.exportTable('csv')">CSV</button>&nbsp;
    <!-- <button class="btn btn-info" style="margin-bottom: 5px;margin-top: 5px;"
      (click)="exporter.exportTable('json')">JSON</button> -->


    <mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[5,10]" showFirstLastButtons 
    [length]="dataSourceTotalSize" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
  <!-- <div class="paginator-style">
  <mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[7,14]" showFirstLastButtons></mat-paginator>
</div> -->
  <table class="table-container" id="criticlTicktsTbl" mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort>

    <ng-container matColumnDef="Id">
      <th class="pl-0" mat-header-cell *matHeaderCellDef mat-sort-header> Alert ID </th>
      <td class="pl-0" mat-cell *matCellDef="let element"> <a  class="criticlEvnt">{{element.Id}}</a> </td>
    </ng-container>


    <ng-container matColumnDef="AlertName">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Alert Name </th>
      <td mat-cell *matCellDef="let element"> {{element.AlertName}} </td>
    </ng-container>

    <ng-container matColumnDef="Value">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
    </ng-container>

    <ng-container matColumnDef="AssetName">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Asset Name</th>
      <td mat-cell *matCellDef="let element"> {{element.AssetName}} </td>
    </ng-container>


    <ng-container matColumnDef="rcretime">
      <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </th>
      <td mat-cell *matCellDef="let element"> {{element.rcretime}} </td>
    </ng-container>


    <ng-container matColumnDef="details">
      <th scope="col" mat-header-cell *matHeaderCellDef> Details </th>
      <td mat-cell *matCellDef="let element">
        <!-- <button mat-icon-button color="accent" (click)="redirectToDetails(element.id)"> -->
        <!-- <mat-icon> <img src="assets/images/details.png" /></mat-icon> -->
        <!-- <i class="far fa-file-image font-icon-size"></i> -->
        <!-- <button mat-icon-button (click)="redirectToDetails(element.id)">
        </button> -->
        <a class="btn btn-primary" style="color: rgb(255, 217, 0);" >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="update">
      <th class="pr-0" mat-header-cell *matHeaderCellDef> Update </th>
      <td class="pr-0" mat-cell *matCellDef="let element">
        <!-- <button mat-icon-button  (click)="redirectToUpdate(element.id)">
          <mat-icon> <img src="assets/images/update1.png" /></mat-icon>
          <img src="assets/images/update1.png" />
        </button> -->
        <a class="btn btn-primary" style="color: green;" (click)="redirectToUpdate(element.id)">
          <i class="fa fa-edit pl-1"></i>
        </a>
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> Delete </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="warn" (click)="redirectToDelete(element.id)">
          <mat-icon> <img src="assets/images/trash.png" /> </mat-icon>
          <mat-icon><i class="fas fa-trash-alt font-icon-size"></i> </mat-icon>
        </button>
      </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height: 10px;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <div *ngIf="loadingSpin" class="d-flex justify-content-center">
    <!-- <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div> -->
        <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image"/>
  </div>

  <ng-template #FormTemplate>
    <div class="modal-body text-center border-left">
      <div class="modalPopupCard modalCancel">
        <div class="modalPopupCardBody card-body">
          <p>ID: {{ Id }}</p>
          <p>Asset Name: {{ assetName }}</p>
        </div>
        <div class="card-footer">
          <div class="row float-right">
            <button
              type="button"
              class="btn btn-danger d-inline-block cursor-pointer"
              (click)="close()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

</div>