<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-group">
        <div id="saleOrreplenishment">
            <label class="mr-sm-2 sr-only" for="inlineFormCustomSelect">Type</label>
            <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" formControlName="saleOrreplenishment" (change)="populatePriority($event.target.value)">
                <option selected>Service Type...</option>
                <option *ngFor="let salesType of salesTypes">{{salesType}}</option>
            </select>
        </div>
        <br>
        <div id="sim">
            <label class="mr-sm-2 sr-only" for="inlineFormCustomSelectSim">Type</label>
            <select class="custom-select mr-sm-2" id="inlineFormCustomSelectSim"
                (change)="selectOption($event.target.value)">
                <option selected>SIM Type...</option>
                <option value="1">Manual</option>
                <option value="2">Auto</option>
            </select>
        </div>
        <br>
        <div *ngIf="enableManualFormEntries">
            <div id="assetId">
                <label class="mr-sm-2 sr-only" for="inlineFormCustomSelectassetId">Machine Name</label>
                <select class="custom-select mr-sm-2" id="inlineFormCustomSelectassetId" formControlName="assetId"
                (change)="populateAssetDetails($event.target.value)">
                    <option selected>Select Asset...</option>
                    <option *ngFor="let assetId of assetList">{{assetId}}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="location">Location</label>
                <input disabled formControlName="location" id="location" type="text" class="form-control" value="f.location"/>

                <div *ngIf="f.location.touched && f.location.invalid" class="alert alert-danger">
                    <div *ngIf="f.location.errors.required">Value is required.</div>
                </div> 
                
            </div>

            <!-- <div class="form-group">
                <label for="podBarCode">POD Bar Code</label>
                <input disabled formControlName="podBarCode" id="podBarCode" type="text" class="form-control" value=f.podBarCode />

                <div *ngIf="f.podBarCode.touched && f.podBarCode.invalid" class="alert alert-danger">
                    <div *ngIf="f.podBarCode.errors.required">Value is required.</div>
                </div>
            </div> -->
            <div id="podBarCode">
                <label class="mr-sm-2 sr-only" for="podBarCode">POD Bar Code</label>
                <select class="custom-select mr-sm-2" id="podBarCode" formControlName="podBarCode">
                    <option selected>Select POD Bar Code...</option>
                    <option *ngFor="let podCode of podCodes">{{podCode}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="podNumber">Number Of PODs</label>
                <input formControlName="podNumber" id="podNumber" type="text" class="form-control" />

                <div *ngIf="f.podNumber.touched && f.podNumber.invalid" class="alert alert-danger">
                    <div *ngIf="f.podNumber.errors.required">Value is required.</div>
                </div>
            </div>

            <div class="form-group">
                <label for="priorityLevel">Priority Level</label>
                <input formControlName="priorityLevel" id="priorityLevel" type="text" class="form-control" />

                <div *ngIf="f.priorityLevel.touched && f.priorityLevel.invalid" class="alert alert-danger">
                    <div *ngIf="f.priorityLevel.errors.required">Value is required.</div>
                </div>
            </div>

            <div class="form-group">
                <label for="timestamp">Timestamp</label>
                <input formControlName="timestamp" id="timestamp" value="f.timestamp"  class="form-control">

                <div *ngIf="f.timestamp.touched && f.timestamp.invalid" class="alert alert-danger">
                    <div *ngIf="f.timestamp.errors.required">Value is required.</div>
                </div>
            </div>
        </div>
    </div>

    <button *ngIf="enableManualFormEntries" class="btn btn-info" type="submit">Trigger Now</button>&nbsp;
    <!-- <button *ngIf="enableAutoSumulateTable" class="btn btn-info" type="submit">Simulate</button>&nbsp; -->
    <!-- <button class="btn btn-info" type="button" (click)="resetValue()">Reset Value</button> -->
</form>
<br>
<div *ngIf="enableAutoSumulateTable">
    <p>Select Device(s) to Simulate:</p>
   <app-device-list></app-device-list>
</div>
<div *ngIf="loadingSpin" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
        <span class="sr-only" id="loading"></span>
    </div>
</div>
{{responseString}}