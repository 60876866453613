import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { HostListener } from "@angular/core";
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
export interface AlertDetails {
  id: number;
  severity: string;
  createdTime: number;
  deviceName : string;
  catagory : string;
  errorCode : number;
  message : string;


}

export interface ServiceTicketsData {
  Id: number;
  ticketId: string;
  RCreTime: Date;
  status: string;
  action: string;
  RModTime: Date;
  sla: Date;
  alertId: string;

 // details: string;
}

@Component({
  selector: 'app-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: ['./alert-table.component.css']
})
export class AlertTableComponent implements OnInit,OnDestroy {
  filterByStatus:string='All';
  scrHeight:any;
  scrWidth:any;
  interval: any;
  dataSourceTotalSize : any;
  position;
  orientation;
  autoRefreshPeriod = environment.serviceTicketsAutoRefreshPeriodInSeconds * 1000;
  pageIndex: string = '0';
  pageSize: string = '5';
  statusmessage: string ='';
  statusTypes:any= ['All','Open','Closed','Scheduled','In Progress','Completed'];
  
  //@HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
  }
  view: any[];
  tabsData: Tab[] = this.tabDataByDesc('Drive train failure');

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if(this.scrWidth > 641 && this.scrWidth <= 961){
      this.position = 'bottom';
      this.orientation = 'horizontal';
    }
    else if( this.scrWidth > 961 && this.scrWidth <= 1025){
      this.position = 'bottom';
      this.orientation = 'horizontal';
    }
    else{
      this.position = 'right';
      this.orientation = 'vertical';
    }

}

  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.onOrientationChange();
    this.getScreenSize();
    this.getServiceTickets(this.pageIndex,this.pageSize,this.filterByStatus);
    this.getserviceTicketsAfter10Sec();
    if(this.scrWidth > 641 && this.scrWidth <= 961){
      this.view = [225, 200];
    }
    else if( this.scrWidth > 961 && this.scrWidth <= 1025){
      this.view = [295, 200];
    }
    else{
      this.view = [350, 300];
    }
    this.interval = setInterval(() => {
      // this.getServiceTickets(0,5)

    }, this.autoRefreshPeriod);
    this.getTicketsSummary();
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getserviceTicketsAfter10Sec() {
    setInterval(()=>this.getServiceTickets(this.pageIndex, this.pageSize,this.filterByStatus), 10000);

  }

  getServiceTickets(offset, limit,status:string) {
    this.loadingSpin = true;
    this.isLoad = false;
    this.SERVICE_TICKET_DATA =[];
    this.getAllServiceTickets(offset, limit,status).subscribe(
      (response) => {

        response.serviceTickets.content.forEach(element => {
         this.SERVICE_TICKET_DATA.push(element);
        });
        //Sort the records by RCreTime
        //this.serviceTicketData =  this.SERVICE_TICKET_DATA.sort((a,b) => (a.RCreTime < b.RCreTime) ? 1 : ((b.RCreTime < a.RCreTime) ? -1 : 0))
        //this.serviceTicketData =  this.SERVICE_TICKET_DATA.sort((a, b) => new Date(b.RCreTime).getTime() - new Date(a.RCreTime).getTime());
        this.serviceTicketData = this.SERVICE_TICKET_DATA;

        console.log("length of service tickets: " + response.serviceTickets.total)
        this.dataSourceTotalSize = response.serviceTickets.total;

        if(this.dataSourceTotalSize==0  ){
           this.statusmessage=`No Service Tickets to show from status "${status}" . Kindly choose another status`;
        }


        this.isLoad = true;
        let table=document.getElementById('serviceTcktTbl');
        table.querySelector('tbody').style.display='revert';
        this.loadingSpin = false;
      },
      (error) => {
        console.log("There Was A Problem Getting All Service Tickets");
        this.loadingSpin = false;
      });
  }
  getAllServiceTickets(offset, limit,status:string): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', offset);
    params = params.set('size', limit);
    params = params.set('status',status);
    params = params.set('sort', 'r_mod_time,desc');
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrl+'?'+params.toString(), "", { 'headers': headers })
  }

  filterStatusChange(event:any){
    this.statusmessage='';
    this.filterByStatus=event?.status;
    this.getServiceTickets(this.pageIndex, this.pageSize,this.filterByStatus);
  }

  getTicketsSummary(){
    this.loadingSpinInPie = true;
    this.getOverallTicketStatus().subscribe(
      (response) => {
       response.operations.forEach(element => {
        //console.log("Data: " + JSON.stringify(element));
        this.OVERALL_TICKET_STATUS.push(element);
        });
        this.OVERALL_TICKET_STATUS.forEach(element => {
          this.serviceTicketNew = element.serviceTicketNew;
          this.serviceTicketClosed = element.serviceTicketClosed;
          this.serviceTicketInprogress = element.serviceTicketInprogress;
        });
        this.data= [{
          kind: 'Closed', share: this.serviceTicketNew
        }, {
          kind: 'New', share: this.serviceTicketClosed
        }, {
          kind: 'In Progress', share: this.serviceTicketInprogress
        }];
        this.isLoadTicketStatus = true;
        this.loadingSpinInPie = false;
      },
      (error) => {
        console.log("There Was A Problem Getting All Service Tickets");
        this.loadingSpinInPie = false;
      });
  }
  getOverallTicketStatus(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrlTicketStatus, "", { 'headers': headers })
  }
  public disableEvent(e) {
    e.preventDefault();
  }
  //alertTableData = ALERT_EVENT_DATA;
  //alertTableHeader = { 'id': 'SL No.', 'severity': 'Severity', 'createdTime':'Created Time', 'deviceName': 'Device Name', 'catagory': 'Catagory', 'errorCode': 'Error Code', 'message': 'Messages'};
  SERVICE_TICKET_DATA: ServiceTicketsData[] = [];
  OVERALL_TICKET_STATUS: TicketsSummary[] = [];
  serviceTicketData : any;
  isLoad : boolean = false;
  loadingSpin : boolean = false;
  loadingSpinInPie : boolean = false;
  //serviceTicketTableHeader =  { 'Id': 'SL No.', 'ticketId':'Ticket ID','RCreTime':'Created Time', 'status': 'Status', 'RModTime': 'Modified Time', 'sla': 'SLA'};
  serviceTicketTableHeader= {'alertId':'Alert ID','ticketId':'Ticket ID','freeFld1':'Asset Name','details':'Description','RCreTime':'Created Time','status': 'Status'};
  apiUrl: string = environment.coreApiBaseUrl + environment.  allServiceTicketsEndPoint;
  apiUrlTicketStatus: string = environment.coreApiBaseUrl + environment.operationSummaryEndPoint;
  serviceTicketNew: any;
  serviceTicketClosed: any;
  serviceTicketInprogress: any;

  public autofit = true;
  public data: any[];
  isLoadTicketStatus: boolean = false;
  // public data: any[] = [{
  //   kind: 'Closed', share: 60
  // }, {
  //   kind: 'New', share: 20
  // }, {
  //   kind: 'InProgress', share: 20
  // }];

  public labelContent(e: any): string {
    return e.category+ ":" + " " + e.value + "%";
  }
  pageChanged(event){

    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;

    let previousSize = pageSize * pageIndex;

    this.getServiceTickets((pageIndex).toString(), pageSize.toString(),this.filterByStatus);
  }
  pageChangedOnClick(event){
    let table=document.getElementById('serviceTcktTbl');
    table.querySelector('tbody').style.display='none';
    this.pageIndex = event.offset;
    this.pageSize = event.limit;
    this.getServiceTickets((this.pageIndex).toString(), this.pageSize.toString(),this.filterByStatus);
  }

  isSyncRequired(isSync: boolean) {
    if(isSync) {
      this.getServiceTickets((this.pageIndex).toString(), this.pageSize.toString(),this.filterByStatus);
    }
  }

  tabDataByDesc(desc) {
    const selectedTab = TABS_DATA.find(({description}) => description === desc);
    return !selectedTab ? TABS_DATA[0].data : selectedTab.data;
  }

  onDetailClick(description) {
    this.tabsData = this.tabDataByDesc(description);
  }
}
export interface TicketsSummary {
  serviceTicketNew: string;
  serviceTicketClosed: string;
  serviceTicketInprogress: string;
}

export interface TabModel {
  description: string,
  data: Tab[]
}

export interface Tab {
    label: string,
    title: string,
    description: {
        list: {
          style: boolean,
          type?: string
        },
        data: {
          label: string
        }[],

    }
}

const TABS_DATA: TabModel[] = [
  {
    description: 'Freezer Temperature Out Of Range',
    data: [
        {
            label: 'Description',
            title: 'Freezer Temperature Out Of Range',
            description: {
                list: {
                    style: false,
                    type: 'number'
                },
                data: [{
                    label: 'The freezer temperature has hit 18°F. The optimum temperature range is -15°F to +10°F.'
                }]
            }
        },
        {
            label: 'Root Causes',
            title: 'Root Causes for variation in Freezer Temperature',
            description: {
                list: {
                    style: true,
                    type: 'number'
                },
                data: [{
                    label: 'Control Circuit Board(CCB) is malfunctioning.'
                },
                {
                    label: 'The coolant tube has leak.'
                },
                {
                    label: 'The condenser fan motor is malfunctioning.'
                }]
            }
        },
        {
            label: 'Prescriptive',
            title: 'Technician Checks on Site Visits:',
            description: {
                list: {
                    style: true,
                    type: 'number'
                },
                data: [{
                    label: 'Run diagnosis on CCB, if needed, Replace it.'
                },
                {
                    label: 'Verify Condenser Fan Motor rpm.'
                },
                {
                    label: 'Verify Refrigerant Gas Leakage.'
                }]
            }
        }
    ]
}
]
