import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env';

@Injectable({
    providedIn: 'root',
})

export class KeyVaultService {
  
constructor(private http:HttpClient,private authService:MsalService) { }

 getAllKeySecrets() {
  let idToken = localStorage.getItem('msal.idtoken');
  const headers = {
    'Authorization':'Bearer '+idToken
  }
  const body = [
    {
      'keyName': 'tsienvironmentid',
    },
    {
      'keyName': 'coreapibaseurl',
    },
    {
      'keyName': 'azuremapsubscriptionkey',
    },
    {
      'keyName': 'azuredigitaltwinurl',
    },
    {
      'keyName': 'redirecturi',
    },
    {
      'keyName': 'evalertlogicappendpoint',
    },
    {
      'keyName': 'pdalertlogicappendpoint'
    },
    {
      'keyName': 'rheemalertlogicendpoint'
    }
  ];
  let apiUrl =
  environment.coreApiBaseUrl + 'securedApi/keyVaultKeys';
  return this.http.post(apiUrl, body, { 'headers': headers });
  }
}