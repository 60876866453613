import { Injectable } from '@angular/core';
import { DataService } from '../feature/data.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenRotationService {
  userRole;
  currentTabIndex: number;
  tabInterval: any;
  rotationData: any;

  list = [
    {
      id: 1,
      screen: 'Polar Delight',
      duration: 5,
      status: true
    },
    {
      id: 4,
      screen: 'Electric Vehicle',
      duration: 5,
      status: true
    }
  ]
  

  constructor(private service: DataService) { localStorage.setItem('slideshowlist', JSON.stringify(this.list));}

  slideShow() {
    this.service.setRotationDetail(0, this.rotationData);
  }

  rotation() {
    const data = JSON.parse(localStorage.getItem('slideshow'));
    this.rotationData = data ? data : this.list;
    this.slideShow();
  }
}
