import { Component, OnInit, HostListener } from '@angular/core';
import { CHARTS_DATA } from './ev-predictions.model';
import { EvPredictionsService } from './ev-predictions.service';

@Component({
  selector: 'app-ev-predictions',
  templateUrl: './ev-predictions.component.html',
  styleUrls: ['./ev-predictions.component.css'],
  providers: [EvPredictionsService]
})
export class EvPredictionsComponent implements OnInit {

  public barChartInput: any[] = [];
  public lineChartInput: any[] = [];
  public xAxisLabelName = 'Problem Cause';
  public yAxisLabelName = 'Count';
  innerWidth: any;
  innerHeight: any;
  autofit = true;
  cardData = CHARTS_DATA;
  height: number;
  offsetX: number;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth-200;
    this.innerHeight = window.innerHeight-150;
    if(window.innerWidth <= 768) {
      this.height = 330;
      this.offsetX = 3;
    } else if (window.innerWidth === 820) {
      this.height = 352;
      this.offsetX = 3;
    } else if (window.innerWidth < 1025) {
      this.height = 357;
      this.offsetX = -15;
    } else {
      this.height = 412;
      this.offsetX = -55;
    }
    
  }

  constructor(private evPredictionsService: EvPredictionsService) { }
  ngOnInit() {
    this.onResize();
    this.getPredictionPriority();
    this.getPredictionProblemCauses();
    this.getPredictionMachineIssues();
  }
  getPredictionPriority() {
    this.evPredictionsService.getPredictionRPN().subscribe(
      (response) => {
        this.cardData.forEach(item => {
          if(item.id === 1 && item.type === 'vertical-bar-chart') {
            item.data.widgetData = response;
            item.loading = true;
          }
        });
      },
      (error) => {
        console.log("There Was A Problem");
      });
  }

  getPredictionProblemCauses() {
    this.evPredictionsService.getProblemCauses().subscribe((response) => {
        this.cardData.forEach(item => {
          if(item.id === 3 && item.type === 'kendo-chart-series') {
            item.data.series = response;
            item.loading = true;
          }
        });
      },
      (error) => {
        console.log("There Was A Problem")
      });

  }
  getPredictionMachineIssues() {
    this.evPredictionsService.getMachineIssues().subscribe(
      (response) => {
        const barChartData = [], lineChartData = [];
        let widgetData = response.sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
        widgetData.forEach(element => {
          barChartData.push({ name: element.name, value: element.value });
          lineChartData.push({ name: element.name, value: element.cumulativePercentage });
        });
        this.cardData.forEach(item => {
          if(item.id === 2 && item.type === 'pareto-chart') {
            item.data.barChartData = barChartData;
            item.data.lineChartData = lineChartData;
            item.loading = true;
          }
        });
      },
      (error) => {
        console.log("There Was A Problem");
      });
  }

  public labelContent(e: any): string {
    return e.value + "%";
  }

}