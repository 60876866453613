import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-number-card',
  templateUrl: './custom-number-card.component.html',
  styleUrls: ['./custom-number-card.component.css']
})
export class CustomNumberCardComponent implements OnInit {

  @Input() cardFooterColor : string;
  @Input() attribute : string;
  @Input() attributeValue : string;
  @Input() imageName : string;
  @Input() screenName : string;
  @Input() unit: string = '';
  @Input() attributeValue1: string;


  constructor() { }

  ngOnInit(): void {
  }

}
