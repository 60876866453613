export interface Asset {
    id: number,
    assetId: string,
    serverenableflag: boolean
};

export interface EvOperationData {
    machineStatus?: string,
    RideMode: string,
    bikeOdometerInMiles: any,
    bikeSpeedInMPH: any,
    batteryPowerInWatts: any,
    TimeInSeconds: string,
    SpeedThrottlePercentage: string,
    assetId: string,
    batteryTemperature: any,
    rideModeThrottlePercentage: any,
    bikeMotorTemperature: any,
    bikeRpm: any,
    soCPercentage: any,
    assetLocation?: string,
    estimateRange?: any
}

export interface EvOperationNumberCard {
    attribute: string,
    attributeValue: string,
    imageName: string,
    colorCondition: string,
    class: string,
    unit?: string
}

export interface EvOperationCard {
    type: 'GAUGE_CARD' | 'CARD_WIDGET',
    subType?: string,
    widgetName: string,
    widgetData: any,
    min: string,
    max: string,
    unit: string,
    class: string,
    needleValue?: string,
    leftFooterName?: string,
    scaleRange?: any
}

export const EV_NUMBER_CARD_DATA: EvOperationNumberCard[] = [
    {
        attribute: 'EV Status',
        attributeValue: 'machineStatus',
        imageName: '',
        colorCondition: 'Online',
        class: 'col'
    },
    {
        attribute: 'Time (Sec)',
        attributeValue: 'TimeInSeconds',
        imageName: '',
        colorCondition: '',
        class: 'col col-style'
    },
    {
        attribute: 'Throttle (%)',
        attributeValue: 'SpeedThrottlePercentage',
        imageName: '',
        colorCondition: '',
        class: 'col col-style'
    },
    {
        attribute: 'Ride Mode',
        attributeValue: 'RideMode',
        imageName: '',
        colorCondition: '',
        class: 'col col-style'
    },
    {
        attribute: 'Asset ID',
        attributeValue: 'assetId',
        imageName: '',
        colorCondition: '',
        class: 'col col-style'
    }
];

/**
 * Widget Model
 * Accepted Type: 'GAUGE_CARD' and 'CARD_WIDGET'
 */
export const EV_OPERATION_CARD_DATA: EvOperationCard[] = [
    {
        type: 'CARD_WIDGET',//'GAUGE_CARD',
        subType: 'widget_5',
        widgetName: 'Odometer',
        // needleValue: 'needleValue',
        widgetData: {
            parent: 'bikeOdometerInMiles',
            child: 'bikeOdometerInMiles'
        },
        min: '',
        max: '',
        unit: ' Miles',
        class: 'col-md-3',
        // scaleRange: DEFAULT_RANGE
    },
    {
        type: 'GAUGE_CARD',
        widgetName: 'RPM',
        needleValue: 'needleValue',
        widgetData: {
            parent: 'bikeRpm',
            child: 'bikeRpm'
        },
        unit: '',
        min: 'min',
        max: 'max',
        class: 'col-md-3 col-style1',
        // scaleRange: DEFAULT_RANGE
    },
    {
        type: 'GAUGE_CARD',
        widgetName: 'Speed',
        needleValue: 'needleValue',
        widgetData: {
            parent: 'bikeSpeedInMPH',
            child: 'bikeSpeedInMPH'
        },
        unit: ' MPH',
        min: 'min',
        max: 'max',
        class: 'col-md-3 col-style1',
        // scaleRange: DEFAULT_RANGE
    },
    {
        type: 'GAUGE_CARD',
        widgetName: 'Motor Temperature',
        needleValue: 'needleValue',
        widgetData: {
            parent: 'bikeMotorTemperature',
            child: 'bikeMotorTemperature'
        },
        unit: ' °C',
        min: 'min',
        max: 'max',
        class: 'col-md-3 col-style1',
        // scaleRange: DEFAULT_RANGE
    },
    {
        type: 'GAUGE_CARD',
        // subType: 'widget_1',
        widgetName: 'Power',
        widgetData: {
            parent: 'batteryPowerInWatts',
            child: 'batteryPowerInWatts'
        },
        unit: ' W',
        min: 'min',
        max: 'max',
        class: 'col-md-3 mT-25',
        // leftFooterName: 'status',
        needleValue: 'needleValue'
    },
    {
        type: 'GAUGE_CARD',//'CARD_WIDGET',
        // subType: 'widget_3',
        widgetName: 'Battery Charge',
        widgetData: {
            parent: 'soCPercentage',
            child: 'soCPercentage'
        },
        unit: '%',
        min: '',
        max: '',
        class: 'col-md-3 col-style1 mT-25',
        // leftFooterName: 'status'
        needleValue: 'needleValue'
    },
    {
        type: 'CARD_WIDGET',
        subType: 'widget_1',
        widgetName: 'Battery Temperature',
        widgetData: {
            parent: 'batteryTemperature',
            child: 'batteryTemperature'
        },
        unit: ' °C',
        min: 'min',
        max: 'max',
        class: 'col-md-3 col-style1 mT-25',
        leftFooterName: 'status'
    },
    {
        type: 'CARD_WIDGET',
        subType: 'widget_5',
        widgetName: 'Estimated Range',
        widgetData: {
            parent: 'estimateRange',
            child: 'estimateRange'
        },
        unit: ' Miles Remaining',
        min: '',
        max: '',
        class: 'col-md-3 col-style1 mT-25',
        leftFooterName: 'status'
    }
];