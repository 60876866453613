import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-rotation-config-list',
  templateUrl: './rotation-config-list.component.html',
  styleUrls: ['./rotation-config-list.component.css']
})
export class RotationConfigListComponent implements OnInit {

  dataSource;
  SCREEN_LIST: ScreenList[] = [];
  displayedColumns: string[] = ['id', 'screen', 'duration', 'status'];
  selection = new SelectionModel<ScreenList>(true, []);
  selectedAsset: any[] = [];
  @ViewChild('FormTemplate') FormTemplate: TemplateRef<any>;

  list = [
    {
      id: 1,
      screen: 'Polar Delight',
      duration: 5,
      status: true
    },
    {
      id: 2,
      screen: 'CNC',
      duration: null,
      status: false
    },
    // {
    //   id: 3,
    //   screen: 'OPC Simulator',
    //   duration: null,
    //   status: false
    // },
    {
      id: 4,
      screen: 'Electric Vehicle',
      duration: 5,
      status: true
    }
  ];
  config = {
    animated: false,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md center-modal',
  };
  public modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    this.list.forEach(element => {
      this.SCREEN_LIST.push(element);
    });
    this.dataSource = new MatTableDataSource(this.SCREEN_LIST);
    if (this.selectedAsset.length == 0) {
      this.list.forEach(element => {
        if (element.status) {
          this.selectedAsset.push(element)
        };
      });
    }
  }

  submitSelection() {
    localStorage.setItem('slideshow', JSON.stringify(this.selectedAsset));
    this.modalRef = this.modalService.show(this.FormTemplate, this.config)
  }

  close() {
    this.modalRef.hide();
  }

  updateDuration(element) {
    for (const prop of this.selectedAsset) {
      if (element.screen == prop.screen && prop.status) prop.duration = element.duration;
    }
  }

  updateCheckedList(event, element) {
    event ? this.selection.toggle(element) : null;
    const item = { "id": element.id, "screen": element.screen, "duration": element.duration || 1 * 60 * 1000, "status": event.checked };
    if (event.checked) this.selectedAsset.push(item);
    if (!event.checked) {
      const index = this.selectedAsset.findIndex(x => x.screen === element.screen);
      for (const prop of this.selectedAsset) {
        if (element.screen == prop.screen) this.selectedAsset.splice(index, 1);
      }
    }
  }

}

export interface ScreenList {
  id: number;
  screen: string;
  duration: number;
  status: boolean;
}
