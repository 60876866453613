  <ngx-charts-line-chart
  [view]="view"
  [scheme]="colorScheme"
  [legend]="legend"
  [legendPosition]="legendPosition"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [timeline]="timeline"
  [results]="lineChartInputData"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  >
</ngx-charts-line-chart>
