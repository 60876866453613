// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //production: true, //if ng build --prod
  msalConfig: {
    clientId: 'cf1c5c9d-6698-41e5-82ca-2d36f5a5f04b',
    tenantId : '5b973f99-77df-4beb-b27d-aa0c70b8482c',
    redirectUri: "https://eyctns.sbp.eyclienthub.com/",
    evRedirectUri: "https://eyctns.sbp.eyclienthub.com/ev",
    swhRedirectUri: "https://eyctns.sbp.eyclienthub.com/smart-water-heater",
    //swhRedirectUri: "http://localhost:4200/smart-water-heater",
    //redirectUri: "http://localhost:4200/",
    //evRedirectUri: "http://localhost:4200/ev",
    consentScopes: ['user.read', 'openid', 'profile'
      //,'https://api.timeseries.azure.com/user_impersonation'
    ]
  },
  coreApiBaseUrl: "https://saphanaspirk.002.eastus2.containers.sbp.eyclienthub.com/uat/",
  //coreApiBaseUrl: "https://eynse.sbp.eyclienthub.com/",
  evOperationsEndPoint: "securedApi/evOperations",
  landEvOperationsEndPoint: "securedApi/evLandOperations",
  pdOperationsEndPoint: "securedApi/pdOperations",
  plantTwinHierachyEndPoint : "securedApi/plantTwin",
  sapCommonServicesEndPoint: "securedApi/sapCommonService",
  retrieveAssetsListEndPoint: "securedApi/fetchAssets",
  retriveOPCMOdelListEndPoint: "securedApi/retriveOPCMOdel",
  delFlagUpdateEndPoint: "securedApi/updateDelFlag",
  addAssetEndPoint: "securedApi/addOrUpdateAsset",
  retrieveAssetInfoEndPoint: "securedApi/fetchAssetParams",
  updateAssetInfoEndPoint:"securedApi/updateAssetInfo",
  IoTStartCommandEndPoint: "securedApi/opcEdgeCommandServiceStartCall",
  IoTStopCommandEndPoint: "securedApi/opcEdgeCommandServiceStopCall",
  allCriticalEventsEndPoint: "securedApi/getPolarDelightEvents",
  allServiceTicketsEndPoint: "securedApi/getPolarDelightServiceTickets",
  operationSummaryEndPoint: "securedApi/operationSummary",
  evOpSummaryEndPoint: "securedApi/evOperationSummary",
  predictionPriorityEndPoint: "securedApi/mlRPN",
  predictionProblemCausesEndPoint: "securedApi/mlProbelmCause",
  predictionMachineIssuesEndPoint: "securedApi/mlMachineIssue",
  topPowerConsumptions: "securedApi/topPowerConsumption",
  swhAvgConsumption: "securedApi/smartHeaterConsumption",
  evTopBatteryConsumption: "securedApi/topEvBatteryConsumption",
  onlineOfflineStatus: "securedApi/getAssetsStatus",
  fetchDistinctAssets: "securedApi/fetchDistinctAssets",
  startCsvSimulatorEndPoint: "startCsvSimulator",
  stopCsvSimulatorEndPoint: "securedApi/stopCsvSimulator",
  evServiceTicketsEndPoint: "securedApi/getElectricVehicleServiceTickets",
  evCriticalEventsEndPoint: "securedApi/getElectricVehicleEvents",
  swhServiceTicketsEndPoint: "/securedApi/getSmartHeaterServiceTickets",
  swhCriticalEventsEndpoint: "/securedApi/getSmartHeaterEvents",
  serviceStatusUpdateEndPoint: "securedApi/serviceStatusUpdate",
  orderAndScheduleEndPoint: "securedApi/sapOrderAndSchedule",
  evServiceStatusUpdateEndPoint: "securedApi/evServiceStatusUpdate",
  swhServiceStatusUpdateEndPoint: "/securedApi/smartHeaterServiceStatusUpdate",
  fetchAllVehiclesEndPoint: "securedApi/fetchAllVehicles",  
  updateVehicleStatusEndPoint: "securedApi/updateVehicleStatus",
  isSimulatorStatusEndPoint: "securedApi/isSimulatorsRunning",
  opeartionSummaryApiUrl: "securedApi/sapOperationService",
  swhOperationsSummary: "securedApi/smartHeaterOperationSummary",
  cncAlertApi: "securedApi/cncAlertData",
  cncFailureApi: "securedApi/cncFailureData",
  cncComputedData: "securedApi/cncComputedData",
  cncRawData: "securedApi/cncRawData",
  cncAssetHealthData: "securedApi/cncOperations",
  swhAssetHealthData: "securedApi/smartHeaterOperations",
  cncMlMachineIssues: "securedApi/cncMlMachineIssue",
  cncMlProblemCause: "securedApi/cncMlProbelmCause",
  cncMlRPN: "securedApi/cncMlRPN",
  swhMlRPN: "securedApi/smartHeaterMlRPN",
  swhMlProblemCause: "securedApi/smartHeaterMlProbelmCause",
  swhMlMachineIssues: "securedApi/smartHeaterMlMachineIssue",
  cncOperationSummary: "securedApi/cncOperationSummary",
  cncPowerConsumption: "securedApi/cncPowerConsumption",
  cncCriticalEvents: "/securedApi/getCNCEvents",
  cncServiceTicketsUrl: "/securedApi/getCNCServiceTickets",
  cncGetAssetStatusUrl: "securedApi/cncGetAssetsStatus",
  swhGetAssetStatusUrl: "securedApi/smartHeaterGetAssetsStatus",
  operationAutoRefreshPeriodInSeconds:5,
  tsiRealTimeFeedAutoRefreshPeriodInSeconds:10,
  serviceTicketsAutoRefreshPeriodInSeconds:15,
  azureMapAutoRefreshPeriodInSeconds:10,
  evOperationAutoRefreshPeriodInSeconds: 10,
  opcSimulatorStatusCheckPeriodInSeconds: 10,
};
