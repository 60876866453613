import { Component, HostListener, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { Router } from '@angular/router';
import { KeyVaultService } from './components/services/key-vault.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  subscriptions: Subscription[] = [];
  keys;
  title = 'MSAL Angular - Sample App';
  //isIframe = false;
  loggedIn = false;
   
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    location.reload();
  }
  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private router: Router,
    private keyVaultSvc: KeyVaultService
  ) {}

  ngAfterViewInit() {
    //this.reloadHomeContent();
  }

  ngOnInit() {
    let loginSuccessSubscription: Subscription;
    let logoutSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;
    

    loginSuccessSubscription = this.broadcastService.subscribe(
      'msal:loginSuccess',
      
      () => {
        this.keyVaultSvc.getAllKeySecrets().subscribe((data) => {
          this.keys = data;
          localStorage.setItem('tsiEnvironmentId', this.keys[0]['value']);
          localStorage.setItem(
            'azureMapSubscriptionKey',
            this.keys[2]['value']
          );
          localStorage.setItem('azureDigitalTwinUrl', this.keys[3]['value']);
          localStorage.setItem('redirectUri', (this.keys[4]['value']));
          localStorage.setItem('evalertlogicappendpoint', (this.keys[5]['value']));
          localStorage.setItem('pdalertlogicappendpoint', (this.keys[6]['value']));
          localStorage.setItem('rheemalertlogicendpoint', (this.keys[7]['value']));
        });
        
        this.checkAccount();
        const redirectUrl = localStorage.getItem('redirectUrlAfterLogin');
        if(redirectUrl) {
          localStorage.removeItem('redirectUrlAfterLogin');
          this.router.navigate([redirectUrl]);
        }
        // if (this.loggedIn && sessionStorage.getItem('ev_login') === 'true') {
        //   this.router.navigate(['/home/ev']);
        // }
        // else {
        //   this.router.navigate(['/home/polar-delight']);
        // }
      }
    );

    loginFailureSubscription = this.broadcastService.subscribe(
      'msal:loginFailure',
      (error) => {
        if (!this.loggedIn) {
          // this.router.navigate(["/login"]);
        }
        console.log('Login Fails:', error);
      }
    );

    logoutSuccessSubscription = this.broadcastService.subscribe(
      'msal:logoutSuccess',
      () => {}
    );

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);
    this.subscriptions.push(logoutSuccessSubscription);

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        this.router.navigate(['/login']);
        return;
      }

      console.log('Redirect Success: ', response.accessToken);
    });

    this.authService.setLogger(
      new Logger(
        (logLevel, message, piiEnabled) => {
          console.log('MSAL Logging: ', message);
        },
        {
          correlationId: CryptoUtils.createNewGuid(),
          piiLoggingEnabled: false,
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.authService.logout();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  login() {
    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      //this.authService.loginPopup();
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.loggedIn = false;
    // this.router.navigate(["/"]);
    this.authService.logout();
  }

  reloadHomeContent() {
    let url = this.router.url;
    if ((url === '/' || url === '/login') && this.loggedIn) {
      this.router.navigate(['/home']);
    }
  }
}
