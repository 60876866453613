<div class="container" style="max-width: 94%;width: 100%;">
    <div class="row">
      <div class="col-md-2 mt-2">
        <div class="row">
          <img src="assets/images/cncimage.png" alt="Polar Delight" class="img-machine" />
        </div>
        <div class="col-sm-12" >
          <div class="assetIdDropdn">
            <div class="form-group">
              <label for="demo_overview">Asset</label>
                <select class="form-control" data-role="select-dropdown" (change)="onChnageOfAsset($event)">
                <option *ngFor="let asset of assetIdList" [value]='asset.id' [attr.selected]="asset.assetId==assetId ? '' : null">
                    {{asset}}
                </option>
                
              </select>
            </div>
          </div>
          <ul class="image-attributes" style="margin-bottom: 0;padding:0;">
             <p>Asset Id : {{cncOperationData.assetId}}</p>
            <p>Location : {{cncOperationData.assetLocation}}</p>
          </ul>
        </div>
        <div class="pt-4 row">
          <button class="btn btn-primary digitalTwnBtn">
            <a (click)="openDialog()">Digital Twin</a>
          </button>
          
        </div>
      </div>
      <div class="col-md-10 mt-2">
        <div class="row">
          <div class="col">
            <app-custom-number-card [cardFooterColor]="cncOperationData.machineStatus === 'Online' ? 'green' : 'darkred'"
              [attribute]="'Machine Status'" [attributeValue]="cncOperationData.machineStatus" [imageName]=""
              [screenName]="'operation'"></app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Machine Code'"
              [attributeValue]="cncOperationData.assetId" [imageName]="" [screenName]="'operation'">
            </app-custom-number-card>
  
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
              [attribute]="'Shift'" [attributeValue1]="time" [attributeValue]="shift" [imageName]="'ice-cream-cup.png'"
              [screenName]="'cnc'"></app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
              [attribute]="'Air Required'" [attributeValue]="cncOperationData.airRequired+ ' PSI'" [imageName]=""
              [screenName]="'operation'"></app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
              [attribute]="'Chip To Chip Time'" [attributeValue]="cncOperationData.chipToChipTime+ ' Sec'" [imageName]=""
              [screenName]="'operation'"></app-custom-number-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <app-gauge-chart-widget [widgetData]="cncOperationData.powerConsumption.PowerConsumption+' W'"
              [widgetName]="'Power'" [min]="cncOperationData.powerConsumption.min+' W'"
              [max]="cncOperationData.powerConsumption.max+' W'" [needleValue]="cncOperationData.powerConsumption.needleValue">
            </app-gauge-chart-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-gauge-chart-widget [widgetData]="cncOperationData.spindleSpeed.SpindleSpeed+' RPM'"
              [widgetName]="'Speed'" [min]="cncOperationData.spindleSpeed.min+' RPM'"
              [max]="cncOperationData.spindleSpeed.max+' RPM'"
              [needleValue]="cncOperationData.spindleSpeed.needleValue"></app-gauge-chart-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-gauge-chart-widget [widgetData]="cncOperationData.spindleVibration.SpindleVibration+' Hz'"
              [widgetName]="'Spindle Vibration'" [min]="cncOperationData.spindleVibration.min+' Hz'"
              [max]="cncOperationData.spindleVibration.max+' Hz'"
              [needleValue]="cncOperationData.spindleVibration.needleValue"></app-gauge-chart-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-gauge-chart-widget [widgetData]="cncOperationData.temperature.Temperature+' °F'"
              [widgetName]="'Temperature'" [min]="cncOperationData.temperature.min+' °F'"
              [max]="cncOperationData.temperature.max+' °F'" [needleValue]="cncOperationData.temperature.needleValue">
            </app-gauge-chart-widget>
          </div>
        </div>
        <div class="row" style="margin-top: -10px;">
          <div class="col-md-3">
            <app-card-widget [widgetData]="cncOperationData.powerSupply.PowerSupply+' V'" [widgetName]="'Power Supply'"
              [leftFooterName]="pdOperationData.maxRamLoadData.status" [min]="cncOperationData.powerSupply.min+' V'"
              [max]="cncOperationData.powerSupply.max+' V'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="cncOperationData.hydraulicPressure.HydraulicPressure+' PSI'" [widgetName]="'Hydraulic Pressure'"
            [min]="cncOperationData.hydraulicPressure.min+' PSI'" [max]="cncOperationData.hydraulicPressure.max+' PSI'" [leftFooterName]="pdOperationData.cycleStatusData.status"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="cncOperationData.coolantCapacity.CoolantCapacity+ ' Ltr'"
              [widgetName]="'Coolant Capacity'" [leftFooterName]="pdOperationData.ramPositionData.status"
              [min]="cncOperationData.coolantCapacity.min+ ' Ltr'" [max]="cncOperationData.coolantCapacity.max+' Ltr'">
            </app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="cncOperationData.hydraulicOilNAS.HydraulicOilNAS+ ' Microns'"
              [widgetName]="'Hydraulic Oil NAS'" [leftFooterName]="pdOperationData.ramPositionData.status"
              [min]="cncOperationData.hydraulicOilNAS.min+ ' Microns'" [max]="cncOperationData.hydraulicOilNAS.max+ ' Microns'">
            </app-card-widget>
          </div>
        </div>
      </div>
    </div>
  </div>